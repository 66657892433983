import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Header from './Header';
import Footer from './Footer';

function AppLayout({children, centerAlign, pageTitle, isLongPage, footerRightText}) {
    return (
        <div className="flex flex-col bg-gray-200 w-screen h-screen">
            <Header pageTitle={pageTitle}/>

            <div
                className="bg-content-dreamy grow"
                onClick={() => {
                    if (window.hideSideNav) {
                        window.hideSideNav();
                    }
                }}
            >
                {/*<div*/}
                {/*    className={classNames({*/}
                {/*        'h-full': !isLongPage,*/}
                {/*        'h-auto': isLongPage,*/}
                {/*        'bg-content-dreamy': true,*/}
                {/*    })}*/}
                {/*>*/}
                {/*  {children}*/}
                {/*</div>*/}

                {children}
            </div>

            <Footer customText={footerRightText} />
        </div>
    );

}

AppLayout.defaultProps = {
    centerAlign: true,
    children: null,
    footerRightText: '',
    isLongPage: false,
    pageTitle: '',
};

AppLayout.propTypes = {
    centerAlign: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    footerRightText: PropTypes.string,
    isLongPage: PropTypes.bool,
    pageTitle: PropTypes.string,
};

export default AppLayout;
