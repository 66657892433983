import {cloneDeep} from 'lodash';
import {
    SET_DICTIONARY_LETTER_OPEN,
    GET_ENTRIES_STARTING,
    GET_ENTRIES_SUCCESS,
    GET_ENTRIES_ERROR,
    GET_WORDS_SUCCESS,
    SET_SELECTED_ENTRY,
    ADD_WORD_SUCCESS,
    DELETE_WORD_SUCCESS,
    SET_ENTRY_SEARCH_BY,
    SET_ENTRY_SEARCH_QUERY,
    GET_ENTRY_SUCCESS,
    GET_ENTRY_STARTING,
    UPDATE_ENTRY_SUCCESS,
    DELETE_ENTRY_SUCCESS,
    ACTIVATE_ENTRY_SUCCESS,
} from '../../constants/actionTypes';
import initialState from '../../store/initialState';

export default function entriesReducer(state = initialState.dictionaryEntries, action) {
    switch (action.type) {
        case SET_ENTRY_SEARCH_BY: {
            const newState = cloneDeep(state);
            newState.searchBy = action.searchBy;
            return newState;
        }

        case SET_ENTRY_SEARCH_QUERY: {
            const newState = cloneDeep(state);
            newState.lastSearchQuery = action.searchQuery;
            return newState;
        }

        case SET_DICTIONARY_LETTER_OPEN: {
            const {letter, isOpen} = action;
            const newState = cloneDeep(state);

            if (letter) {
                if (!newState[letter]) {
                    newState[letter] = cloneDeep(initialState.dictionaryEntries.letter);
                }

                const entryData = newState[letter];
                entryData.isOpen = isOpen;
            }

            return newState;
        }

        case GET_ENTRIES_STARTING: {
            const {criteria} = action;
            const {letter, searchBy, searchQuery} = criteria;
            const newState = cloneDeep(state);
            let entryData;

            if (letter) {
                if (!newState[letter]) {
                    newState[letter] = cloneDeep(initialState.dictionaryEntries.letter);
                }

                entryData = newState[letter];
            } else if (searchBy) {
                const searchKey = `${searchBy}-${searchQuery.toLowerCase()}`;
                if (!newState[searchKey]) {
                    newState[searchKey] = cloneDeep(initialState.dictionaryEntries.searchQuery);
                }

                entryData = newState[searchKey];
            } else {
                return newState;
            }

            entryData.loading = true;
            entryData.loaded = false;
            entryData.error = null;
            return newState;
        }

        case GET_ENTRIES_SUCCESS: {
            const {criteria, data} = action;
            const {letter, searchBy, searchQuery} = criteria;
            const newState = cloneDeep(state);
            let entryData;

            if (letter) {
                if (!newState[letter]) {
                    newState[letter] = cloneDeep(initialState.dictionaryEntries.letter);
                }

                entryData = newState[letter];
            } else if (searchBy) {
                const searchKey = `${searchBy}-${searchQuery.toLowerCase()}`;
                if (!newState[searchKey]) {
                    newState[searchKey] = cloneDeep(initialState.dictionaryEntries.searchQuery);
                }

                entryData = newState[searchKey];
            } else {
                return newState;
            }

            const ids = [];

            data.entries.forEach((entry) => {
                ids.push(entry.id);
                newState.map[entry.id] = entry;

                if (entry.meanings) {
                    newState.fullMap[entry.id] = entry;
                }
            });

            if (data.wordCounts) {
                for (const entryId in data.wordCounts) {
                    if (newState.fullMap[entryId]) {
                        newState.fullMap[entryId].wordCount = data.wordCounts[entryId];
                    }
                }
            }

            entryData.loading = false;
            entryData.loaded = true;
            entryData.ids = ids;
            return newState;
        }

        case GET_ENTRY_SUCCESS:
        case UPDATE_ENTRY_SUCCESS:
        case ACTIVATE_ENTRY_SUCCESS:
        case DELETE_ENTRY_SUCCESS: {
            const {entryId, data} = action;
            const {entry} = data;
            const newState = cloneDeep(state);
            newState.map[entryId] = entry;
            if (entry.meanings) {
                newState.fullMap[entry.id] = entry;
            }
            return newState;
        }

        case GET_ENTRIES_ERROR: {
            const {criteria} = action;
            const {letter, searchBy, searchQuery} = criteria;
            const newState = cloneDeep(state);
            let entryData;

            if (letter) {
                if (!newState[letter]) {
                    newState[letter] = cloneDeep(initialState.dictionaryEntries.letter);
                }

                entryData = newState[letter];

            } else if (searchBy) {
                const searchKey = `${searchBy}-${searchQuery.toLowerCase()}`;
                if (!newState[searchKey]) {
                    newState[searchKey] = cloneDeep(initialState.dictionaryEntries.searchQuery);
                }

                entryData = newState[letter];
            }

            entryData.loading = false;
            entryData.loaded = false;
            entryData.error = action.err ? action.err.message : 'Failed to get entries';
            return newState;
        }

        case SET_SELECTED_ENTRY: {
            const {entry} = action;
            const newState = cloneDeep(state);
            newState.selectedEntryId = entry ? entry.id : 0;
            return newState;
        }

        case GET_WORDS_SUCCESS: {
            const {criteria, data} = action;
            const {entryId} = criteria;
            const newState = cloneDeep(state);
            newState.fullMap[entryId] = data.entry;
            newState.fullMap[entryId].wordCount = data.wordCount;
            return newState;
        }

        case ADD_WORD_SUCCESS: {
            const {entryId} = action.data;
            const newState = cloneDeep(state);
            if (newState.fullMap[entryId]) {
                newState.fullMap[entryId].wordCount = newState.fullMap[entryId].wordCount + 1;
            }
            return newState;
        }

        case DELETE_WORD_SUCCESS: {
            const {entries} = action.data.word;
            const newState = cloneDeep(state);
            entries.forEach((entry) => {
                const entryId = entry.id;
                if (newState.fullMap[entryId]) {
                    newState.fullMap[entryId].wordCount = newState.fullMap[entryId].wordCount - 1;
                }
            });
            return newState;
        }

        default: {
            return state;
        }
    }
}
