import { cloneDeep } from 'lodash';
import {
  SET_DICTIONARY_LETTERS
} from '../../constants/actionTypes';
import initialState from '../../store/initialState';

function getLetters() {
  return "abcdefghijklmnopqrstuvwxyz".split("").map(l => l.toUpperCase());
}

export default function lettersReducer(state = initialState.dictionaryLetters, action) {
  switch (action.type) {
    case SET_DICTIONARY_LETTERS: {
      const newState = cloneDeep(state);
      newState.letters = getLetters();
      newState.loading = false;
      newState.loaded = true;
      return newState;
    }

    default: {
      return state;
    }
  }
}
