import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  ACTIVATE_ENTRY_STARTING,
  ACTIVATE_ENTRY_SUCCESS,
  ACTIVATE_ENTRY_ERROR,
} from '../../constants/actionTypes';

export default function activateEntryReducer(state = initialState.activateDictionaryEntry, action) {
  switch (action.type) {
    case ACTIVATE_ENTRY_STARTING: {
      const {entryId} = action;
      const newState = clone(state);

      if (!newState[entryId]) {
        newState[entryId] = clone(initialState.activateDictionaryEntry.entryId);
      }

      const activateData = newState[entryId];
      activateData.saving = true;
      activateData.saved = false;
      activateData.error = null;

      return newState;
    }

    case ACTIVATE_ENTRY_SUCCESS: {
      const {entryId} = action;
      const newState = clone(state);

      if (!newState[entryId]) {
        newState[entryId] = clone(initialState.activateDictionaryEntry.entryId);
      }

      const activateData = newState[entryId];
      activateData.saving = false;
      activateData.saved = true;
      activateData.error = null;
      return newState;
    }

    case ACTIVATE_ENTRY_ERROR: {
      const {entryId} = action;
      const newState = clone(state);

      if (!newState[entryId]) {
        newState[entryId] = clone(initialState.activateDictionaryEntry.entryId);
      }

      const activateData = newState[entryId];
      activateData.saving = false;
      activateData.saved = false;
      activateData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
