import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as dictionaryActions from "../../actions/dictionaryActions";
import {connect} from "react-redux";
import {useEffect} from "react";
import LetterItem from "./LetterItem";

function LettersList({ actions, dictionaryLetters, languages }) {
    useEffect(() => {
        if (!dictionaryLetters.loaded) {
            actions.setDictionaryLetters();
        }

        if (!languages.loaded) {
            actions.getLanguages();
        }
    }, []);

    const letterItems = dictionaryLetters.letters.map((letter) => {
        return (
            <LetterItem
                key={letter}
                letter={letter}
            />
        );
    });

    return (
        <div>
            {letterItems}
        </div>
    );
}

LettersList.defaultProps = {
    actions: {},
    dictionaryLetters: {},
    languages: {},
};

LettersList.propTypes = {
    actions: PropTypes.object,
    dictionaryLetters: PropTypes.object,
    languages: PropTypes.object,
};

const mapStateToProps = state => ({
    dictionaryLetters: state.dictionaryLetters,
    languages: state.languages,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, dictionaryActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LettersList);
