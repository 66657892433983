import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Dropzone from 'react-dropzone';
import * as filesActions from '../../actions/filesActions';
import {getFileUri} from "../../utils/navigation";

class FileUpload extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.onDropFile = this.onDropFile.bind(this);
        this.uploadStarted = false;
    }

    /* eslint-disable-next-line react/no-deprecated */
    componentWillReceiveProps(nextProps) {
        if (
            this.props.fileUploadStatus !== nextProps.fileUploadStatus &&
            this.props.fileUploadStatus.uploading && !nextProps.fileUploadStatus.uploading &&
            this.uploadStarted
        ) {
            this.handleStatusChange(nextProps);
        }
    }

    handleStatusChange(nextProps) {
        if (!nextProps.fileUploadStatus.uploaded) {
            // Failed to upload
            alert(nextProps.fileUploadStatus.error);
            this.uploadStarted = false;
        } else {
            // file upload success
            console.log('File upload success!');
            const {file} = nextProps.fileUploadStatus;
            this.props.onFileUploaded(file);
            console.log(nextProps.fileUploadStatus);
            console.log(file);
            this.uploadStarted = false;
        }
    }

    onDropFile(acceptedFiles) {
        if (!(acceptedFiles.length > 0)) {
            return;
        }

        const {fileUploadStatus} = this.props;
        if (fileUploadStatus.uploading) {
            alert('Please wait for previous file to finish uploading');
            return;
        }

        const file = acceptedFiles[0];
        //const { sessionHash } = this.props.currentUser.userSession;
        this.props.actions.uploadFile(file);
        this.uploadStarted = true;
    }

    render() {
        const {dropzoneClass, acceptOnly, fileId, showPreview, showRemove, promptText} = this.props;
        let media = null, btnRemove = null;

        if (fileId && showPreview) {
            const mediaUrl = getFileUri(fileId);
            media = (
                <img src={mediaUrl} alt="Current picture" style={{maxHeight: '350px'}}/>
            );
        }

        if (fileId && showRemove) {
            btnRemove = (
                <div className="mt-3">
                    <a
                        className="cursor-pointer underline"
                        href={null}
                        onClick={() => {
                            this.props.onFileUploaded(null);
                        }}
                    >
                        Remove
                    </a>
                </div>
            )
        }

        let accept = null;

        if (acceptOnly === 'image') {
            accept = {
                'image/jpeg': ['.jpg', '.jpeg'],
                'image/png': ['.png']
            };
        } else if (acceptOnly === 'pdf') {
            accept = {
                'application/pdf': ['.png'],
            };
        } else {
            accept = {
                'video/*': [],
                'image/*': [],
                'text/*': [],
                'application/*': [],
                'audio/*': [],
            };
        }

        return (
            <div>
                <div className="file-upload-container dropzone-container">
                    <Dropzone
                        accept={accept}
                        accepts="image/*,.pdf/*"
                        multiple={false}
                        noKeyboard={true}
                        onDrop={this.onDropFile}
                    >
                        {({getRootProps, getInputProps}) => (
                            <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div className={`dropzone-view pointer ${dropzoneClass}`}>
                                        {media}

                                        <p className="m-0 text-small no-underline">
                                            {promptText}
                                        </p>
                                    </div>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>

                {btnRemove}
            </div>
        );
    }
}

FileUpload.defaultProps = {
    acceptOnly: '',
    actions: {},
    currentUser: {},
    dropzoneClass: 'text-center',
    fileUploadStatus: {},
    fileId: '',
    promptText: 'Click to Upload',
    showPreview: true,
    showRemove: false,
};

FileUpload.propTypes = {
    acceptOnly: PropTypes.string,
    actions: PropTypes.object,
    currentUser: PropTypes.object,
    dropzoneClass: PropTypes.string,
    fileUploadStatus: PropTypes.object,
    fileId: PropTypes.number,
    onFileUploaded: PropTypes.func.isRequired,
    promptText: PropTypes.string,
    showPreview: PropTypes.bool,
    showRemove: PropTypes.bool,
};

const mapStateToProps = state => ({
    currentUser: state.currentUser,
    fileUploadStatus: state.fileUploadStatus,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, filesActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
