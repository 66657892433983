import {
  GET_SEARCH_RESULT_STARTING,
  GET_SEARCH_RESULT_SUCCESS,
  GET_SEARCH_RESULT_ERROR,

  GET_SEARCH_SUGGESTIONS_STARTING,
  GET_SEARCH_SUGGESTIONS_SUCCESS,
  GET_SEARCH_SUGGESTIONS_ERROR,

  SAVE_SEARCH_RESULT_STARTING,
  SAVE_SEARCH_RESULT_SUCCESS,
  SAVE_SEARCH_RESULT_ERROR,
} from '../constants/actionTypes';
import axios from "../axios";
import {
  GET_SEARCH_RESULT,
  GET_SEARCH_SUGGESTIONS,
  SAVE_SEARCH_RESULT,
} from '../constants/apiUrls';

export function getSearchSuggestionsStarting(query) {
  return { type: GET_SEARCH_SUGGESTIONS_STARTING, query };
}

export function getSearchSuggestionsSuccess(query, data) {
  return { type: GET_SEARCH_SUGGESTIONS_SUCCESS, query, data }
}

export function getSearchSuggestionsFailed(query, err) {
  return { type: GET_SEARCH_SUGGESTIONS_ERROR, query, err }
}

export function getSearchSuggestions(searchQuery) {
  return dispatch => (
    axios.get(GET_SEARCH_SUGGESTIONS, {
      params: {
        q: searchQuery,
      }
    }).then((response) => {
      if (response && response.status === 200) {
        dispatch(getSearchSuggestionsSuccess(searchQuery, response.data));
        return;
      }
      const err = (response && response.data && response.data.error) ?
        new Error(response.data.error) : new Error('Failed to fetch suggestions for search query');
      dispatch(getSearchSuggestionsFailed(searchQuery, err));
    }).catch((err) => {
      const error = (err.response && err.response.data && err.response.data.error) ?
        new Error(err.response.data.error) : err;
      dispatch(getSearchSuggestionsFailed(searchQuery, error));
    }));
}

export function getSearchResultStarting(query) {
  return { type: GET_SEARCH_RESULT_STARTING, query };
}

export function getSearchResultSuccess(query, data) {
  return { type: GET_SEARCH_RESULT_SUCCESS, query, data }
}

export function getSearchResultFailed(query, err) {
  return { type: GET_SEARCH_RESULT_ERROR, query, err }
}

export function getSearchResult(query) {
  return dispatch => (
    axios.post(GET_SEARCH_RESULT, {
      q: query,
    }).then((response) => {
      if (response && response.status === 200 && response.data.success) {
        dispatch(getSearchResultSuccess(query, response.data));
        return;
      }
      const err = (response && response.data && response.data.error) ?
        new Error(response.data.error) : new Error('Failed to get search result');
      dispatch(getSearchResultFailed(query, err));
    }).catch((err) => {
      const error = (err.response && err.response.data && err.response.data.error) ?
        new Error(err.response.data.error) : err;
      dispatch(getSearchResultFailed(query, error));
    }));
}

export function saveSearchResultStarting(query) {
  return { type: SAVE_SEARCH_RESULT_STARTING, query };
}

export function saveSearchResultSuccess(query, data) {
  return { type: SAVE_SEARCH_RESULT_SUCCESS, query, data }
}

export function saveSearchResultFailed(query, err) {
  return { type: SAVE_SEARCH_RESULT_ERROR, query, err }
}

export function saveSearchResult(query, postData) {
  return dispatch => (
    axios.patch(SAVE_SEARCH_RESULT, {
      q: query,
      ...postData,
    }).then((response) => {
      if (response && response.status === 200 && response.data.success) {
        dispatch(saveSearchResultSuccess(query, response.data));
        return;
      }
      const err = (response && response.data && response.data.error) ?
          new Error(response.data.error) : new Error('Failed to save search result');
      dispatch(saveSearchResultFailed(query, err));
    }).catch((err) => {
      const error = (err.response && err.response.data && err.response.data.error) ?
          new Error(err.response.data.error) : err;
      dispatch(saveSearchResultFailed(query, error));
    }));
}
