import axios from '../axios';
import {
    GET_PRODUCTS_STARTING,
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_ERROR,

    GET_PRODUCT_BY_ID_STARTING,
    GET_PRODUCT_BY_ID_SUCCESS,
    GET_PRODUCT_BY_ID_ERROR,

    GET_SHOPPING_CART_STARTING,
    GET_SHOPPING_CART_SUCCESS,
    GET_SHOPPING_CART_ERROR,

    SET_CART_QUANTITY_STARTING,
    SET_CART_QUANTITY_SUCCESS,
    SET_CART_QUANTITY_ERROR,
} from '../constants/actionTypes';
import {
    GET_PRODUCTS,
    GET_PRODUCT_BY_ID,
    SET_CART_QUANTITY,
    GET_SHOPPING_CART,
} from '../constants/apiUrls';

export function getProductsStarting() {
    return { type: GET_PRODUCTS_STARTING };
}

export function getProductsSuccess(data) {
    return { type: GET_PRODUCTS_SUCCESS, data };
}

export function getProductsError(err) {
    return { type: GET_PRODUCTS_ERROR, err };
}

export function getProducts(postData) {
    return (dispatch) => {
        dispatch(getProductsStarting());

        axios.post(GET_PRODUCTS, postData).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(getProductsSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to get Products';
            dispatch(getProductsError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(getProductsError(error));
        });
    };
}

export function getProductByIdStarting(productId) {
    return { type: GET_PRODUCT_BY_ID_STARTING, productId };
}

export function getProductByIdSuccess(productId, data) {
    return { type: GET_PRODUCT_BY_ID_SUCCESS, productId, data };
}

export function getProductByIdError(productId, err) {
    return { type: GET_PRODUCT_BY_ID_ERROR, productId, err };
}

export function getProductById(productId) {
    return (dispatch) => {
        dispatch(getProductByIdStarting(productId));

        const url = GET_PRODUCT_BY_ID.replace('{productId}', productId);
        axios.get(url).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(getProductByIdSuccess(productId, response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to get Product Id';
            dispatch(getProductByIdError(productId, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(getProductByIdError(productId, error));
        });
    };
}

export function setCartQuantityStarting(productId) {
    return { type: SET_CART_QUANTITY_STARTING, productId };
}

export function setCartQuantitySuccess(productId, data) {
    return { type: SET_CART_QUANTITY_SUCCESS, productId, data };
}

export function setCartQuantityError(productId, err) {
    return { type: SET_CART_QUANTITY_ERROR, productId, err };
}

export function setCartQuantity(productId, qty) {
    return (dispatch) => {
        dispatch(setCartQuantityStarting(productId));

        axios.patch(SET_CART_QUANTITY, {
            productId,
            qty,
        }).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(setCartQuantitySuccess(productId, response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to send Cart request';
            dispatch(setCartQuantityError(productId, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(setCartQuantityError(productId, error));
        });
    };
}

export function getShoppingCartStarting() {
    return { type: GET_SHOPPING_CART_STARTING };
}

export function getShoppingCartSuccess(data) {
    return { type: GET_SHOPPING_CART_SUCCESS, data };
}

export function getShoppingCartError(err) {
    return { type: GET_SHOPPING_CART_ERROR, err }
}

export function getShoppingCart() {
    return (dispatch) => {
        dispatch(getShoppingCartStarting());

        axios.get(GET_SHOPPING_CART).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(getShoppingCartSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to get cart details';
            dispatch(getShoppingCartError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(getShoppingCartError(error));
        });
    };
}

