import {cloneDeep} from 'lodash';
import {
    GET_WORDS_STARTING,
    GET_WORDS_SUCCESS,
    GET_WORDS_ERROR,
    SET_SELECTED_WORD,
    ADD_WORD_SUCCESS,
    UPDATE_WORD_SUCCESS,
    DELETE_WORD_SUCCESS, ACTIVATE_WORD_SUCCESS,
} from '../../constants/actionTypes';
import initialState from '../../store/initialState';

export default function wordsReducer(state = initialState.dictionaryWords, action) {
    switch (action.type) {
        case GET_WORDS_STARTING: {
            const {criteria} = action;
            const {entryId} = criteria;
            const newState = cloneDeep(state);

            if (!newState[entryId]) {
                newState[entryId] = cloneDeep(initialState.dictionaryWords.entryId);
            }

            const wordsData = newState[entryId];
            wordsData.loading = true;
            wordsData.loaded = false;
            wordsData.error = null;

            return newState;
        }

        case GET_WORDS_SUCCESS: {
            const {criteria, data} = action;
            const {entryId} = criteria;
            const newState = cloneDeep(state);

            if (!newState[entryId]) {
                newState[entryId] = cloneDeep(initialState.dictionaryWords.entryId);
            }

            const ids = [];
            data.words.forEach((word) => {
                ids.push(word.id);
                newState.map[word.id] = word;
            });

            const wordsData = newState[entryId];
            wordsData.loading = false;
            wordsData.loaded = true;
            wordsData.ids = ids;

            return newState;
        }

        case GET_WORDS_ERROR: {
            const {criteria} = action;
            const {entryId} = criteria;
            const newState = cloneDeep(state);

            if (!newState[entryId]) {
                newState[entryId] = cloneDeep(initialState.dictionaryWords.entryId);
            }

            const wordsData = newState[entryId];
            wordsData.loading = false;
            wordsData.loaded = false;
            wordsData.error = action.err ? action.err.message : 'Failed to get words in entry';
            return newState;
        }

        case SET_SELECTED_WORD: {
            const {word} = action;
            const newState = cloneDeep(state);
            newState.selectedWordId = word ? word.id : 0;
            return newState;
        }

        case ADD_WORD_SUCCESS: {
            const {word, entryId} = action.data;
            const wordId = word.id;

            const newState = cloneDeep(state);
            newState.map[wordId] = word;

            if (!newState[entryId]) {
                newState[entryId] = cloneDeep(initialState.dictionaryWords.entryId);
            }

            const wordsData = newState[entryId];
            wordsData.ids.push(wordId);
            return newState;
        }

        case UPDATE_WORD_SUCCESS:
        case DELETE_WORD_SUCCESS:
        case ACTIVATE_WORD_SUCCESS: {
            const {wordId, data} = action;
            const newState = cloneDeep(state);
            newState.map[wordId] = data.word;
            newState.fullMap[wordId] = data.word;
            return newState;
        }

        default: {
            return state;
        }
    }
}
