import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {brandName, companyName, copyrightYear} from "../../constants/appConfig";

function Footer({clientInfo, customText}) {
    const {data: clientInfoData} = clientInfo;

    let rightContent;

    if (customText) {
        rightContent = customText;
    } else {
        rightContent = `${clientInfoData.name}, ${clientInfoData.location}.`;
    }

    return (
        <div className="footer shadow-lg bg-neutral">
            <div className="container mx-auto">
                <div className="py-2 flex flex-row w-full justify-between">
                    <div className="basis-1/2 flex justify-start">
                        <img alt={`${brandName} Logo`} src="/img/logo.png" className="w-6 h-6 mr-2"/>

                        <div className="text-center text-white flex items-center">
                            {brandName}
                        </div>
                    </div>

                    <div className="basis-1/2 text-right text-white">
                        {rightContent}
                    </div>
                </div>
            </div>
        </div>
    );
}

Footer.defaultProps = {
    actions: {},
    clientInfo: {},
    customText: '',
};

Footer.propTypes = {
    actions: PropTypes.object,
    clientInfo: PropTypes.object,
    customText: PropTypes.string,
};

const mapStateToProps = state => ({
    clientInfo: state.clientInfo,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
