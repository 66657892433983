import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  ACTIVATE_WORD_STARTING,
  ACTIVATE_WORD_SUCCESS,
  ACTIVATE_WORD_ERROR,
} from '../../constants/actionTypes';

export default function activateWordReducer(state = initialState.activateDictionaryWord, action) {
  switch (action.type) {
    case ACTIVATE_WORD_STARTING: {
      const {wordId} = action;
      const newState = clone(state);

      if (!newState[wordId]) {
        newState[wordId] = clone(initialState.activateDictionaryWord.wordId);
      }

      const activateData = newState[wordId];
      activateData.saving = true;
      activateData.saved = false;
      activateData.error = null;

      return newState;
    }

    case ACTIVATE_WORD_SUCCESS: {
      const {wordId} = action;
      const newState = clone(state);

      if (!newState[wordId]) {
        newState[wordId] = clone(initialState.activateDictionaryWord.wordId);
      }

      const activateData = newState[wordId];
      activateData.saving = false;
      activateData.saved = true;
      activateData.error = null;
      return newState;
    }

    case ACTIVATE_WORD_ERROR: {
      const {wordId} = action;
      const newState = clone(state);

      if (!newState[wordId]) {
        newState[wordId] = clone(initialState.activateDictionaryWord.wordId);
      }

      const activateData = newState[wordId];
      activateData.saving = false;
      activateData.saved = false;
      activateData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
