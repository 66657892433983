import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    GET_PLACES_ACCESS_STARTING,
    GET_PLACES_ACCESS_SUCCESS,
    GET_PLACES_ACCESS_ERROR,
    ADD_PLACE_ACCESS_SUCCESS, DELETE_PLACE_ACCESS_SUCCESS,
} from '../../constants/actionTypes';

const sortByOrder = (newState) => {

}

export default function placesAccessReducer(state = initialState.placesAccess, action) {
    switch (action.type) {
        case GET_PLACES_ACCESS_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            newState.error = null;
            return newState;
        }

        case GET_PLACES_ACCESS_SUCCESS: {
            console.log('Inside places success', action.data.placesAccess);
            const newState = cloneDeep(state);
            const ids = [];

            action.data.places.forEach((place) => {
                const placeId = place.id;
                ids.push(placeId);
                newState.map[placeId] = place;
            });

            console.log(ids);

            newState.ids = ids;
            newState.loading = false;
            newState.loaded = true;

            sortByOrder(newState);

            return newState;
        }

        case GET_PLACES_ACCESS_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrieve places.';
            return newState;
        }

        case ADD_PLACE_ACCESS_SUCCESS: {
            const newState = cloneDeep(state);
            const { place } = action.data;
            const placeId = place.id;

            // Push new place data
            newState.map[placeId] = place;
            newState.ids.push(placeId);

            // Sort by display order
            sortByOrder(newState);

            return newState;
        }

        case DELETE_PLACE_ACCESS_SUCCESS: {
            const newState = cloneDeep(state);
            const { placeId } = action;
            newState.ids = newState.ids.filter((id) => id !== placeId);
            return newState;
        }

        default: {
            return state;
        }
    }
}
