import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

function RadioButton({
                      className,
                      label, helperText, hasError, checked, onChange,
                      leftLabel, rightLabel
                  }) {
    const classes = `form-control inline ${className}`;

    let helperLabel = null;
    if (helperText) {
        helperLabel = (
            <label className="label">
                <span className="label-text-alt">{helperText}</span>
            </label>
        );
    }

    let rightLabelTxt = null, leftLabelTxt = null;

    if (label) {
        rightLabelTxt = (
            <span className="label-text ml-4">{label}</span>
        );
    } else {
        if (leftLabel) {
            leftLabelTxt = (
                <span className="label-text mr-4">{leftLabel}</span>
            );
        }

        if (rightLabel) {
            rightLabelTxt = (
                <span className="label-text ml-4">{rightLabel}</span>
            );
        }
    }

    return (
        <div className={classes}>
            <label className="label cursor-pointer justify-start">
                {leftLabelTxt}

                <input
                    type="radio"
                    checked={checked}
                    className="radio"
                    onChange={onChange}
                />

                {rightLabelTxt}
            </label>

            {helperLabel}
        </div>
    );
}

RadioButton.defaultProps = {
    checked: false,
    className: '',
    helperText: '',
    hasError: false,
    leftLabel: '',
    label: '',
    rightLabel: '',
};

RadioButton.propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    helperText: PropTypes.string,
    hasError: PropTypes.bool,
    leftLabel: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    rightLabel: PropTypes.string,
};

export default RadioButton;
