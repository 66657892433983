import React from "react";
import TextInput from "../Common/Utilities/Forms/TextInput";
import {FaPlusCircle, FaTimesCircle} from "react-icons/fa";

function EntryMeaningsEditor({meanings, setMeanings}) {
    const meaningTextBoxes = [];

    meanings.forEach((word, index) => {
        meaningTextBoxes.push((
            <div className="flex flex-row justify-center items-center" key={index}>
                <div className="grow">
                    <TextInput
                        inputClassName={"rounded-r-none"}
                        value={word}
                        placeholder={""}
                        label={""}
                        onChange={(event) => {
                            const meaningsNew = [...meanings];
                            meaningsNew[index] = event.target.value;
                            setMeanings(meaningsNew);
                        }}
                    />
                </div>

                <div className="basis-auto">
                    <button
                        type="button"
                        className="btn btn-error rounded-l-none"
                        onClick={() => {
                            const enteredMeaning = meanings[index] ? meanings[index].trim() : '';
                            if (
                                enteredMeaning === '' ||
                                window.confirm('Are you sure you want to delete this meaning?')
                            ) {
                                const meaningsNew = [...meanings];
                                meaningsNew.splice(index, 1);
                                setMeanings(meaningsNew);
                            }
                        }}
                    >
                        <FaTimesCircle className="w-4 h-4 inline" />
                    </button>
                </div>
            </div>
        ));
    });

    return (
        <div>
            <p className="text-sm">Meanings</p>

            <div className="grid grid-cols-3 gap-4 mt-2 items-center">
                {meaningTextBoxes}

                <div className="">
                    <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                            const meaningsNew = [...meanings];
                            meaningsNew.push('');
                            setMeanings(meaningsNew);
                        }}
                    >
                        <FaPlusCircle className="w-4 h-4 inline"/>
                        Add Meaning
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EntryMeaningsEditor;
