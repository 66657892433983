import LoadingIcon from "../Common/Utilities/LoadingIcon";
import {IoIosArrowDown, IoIosArrowForward} from "react-icons/io";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as dictionaryActions from "../../actions/dictionaryActions";
import {connect} from "react-redux";
import classNames from "classnames";
import {BsThreeDots} from "react-icons/bs";
import {getEntryStr} from "../../utils/dictionary";

function LetterItem({actions, dictionaryEntries, letter}) {
    let leafIcon;
    const isLeafOpen = dictionaryEntries[letter] && dictionaryEntries[letter].isOpen;
    const isChildrenLoading = (dictionaryEntries[letter]) ? dictionaryEntries[letter].loading : false;

    if (isLeafOpen) {
        if (isChildrenLoading) {
            leafIcon = (
                <LoadingIcon className="inline mr-2" color="gray-500" size={5}/>
            );
        } else {
            leafIcon = (
                <IoIosArrowDown className="w-4 h-4 inline mr-2"/>
            );
        }
    } else {
        leafIcon = (
            <IoIosArrowForward className="w-4 h-4 inline mr-2"/>
        );
    }

    let entriesList = null;

    if (isLeafOpen) {
        const entryIds = dictionaryEntries[letter] ? dictionaryEntries[letter].ids : [];

        if (isChildrenLoading) {
            entriesList = (
                <div className="ml-12 py-2">
                    Please wait...
                </div>
            );
        } else if (!entryIds.length) {
            entriesList = (
                <div className="ml-12 py-2">
                    No entries found.
                </div>
            );
        } else {
            const entryItems = entryIds.map((entryId) => {
                const entry = dictionaryEntries.map[entryId];

                return (
                    <div
                        key={`entry-${entryId}`}
                        className="px-1 pt-1 pb-2 ml-6 flex flex-row text-black cursor-pointer hover:bg-gray-200 rounded-md"
                        onClick={() => {
                            actions.setSelectedEntry(entry);
                        }}
                    >
                        <div>
                            <BsThreeDots className="w-4 h-4 inline mr-2"/>
                        </div>
                        <div className={classNames({
                            "mt-1.5 text-sm": true,
                            "text-error line-through": (entry.isActive === 0),
                        })}>
                            {getEntryStr(entry)}
                        </div>
                    </div>
                );
            });

            entriesList = (
                <div>
                    {entryItems}
                </div>
            )
        }
    }

    return (
        <div>
            <div
                className="region-main px-1 py-1 flex flex-row cursor-pointer items-center hover:bg-gray-200 rounded-md"
                onClick={() => {
                    actions.setLetterOpen(letter, !isLeafOpen);

                    // If entries not fetched
                    if (!isLeafOpen) {
                        const alreadyFetched = (dictionaryEntries[letter]) ? dictionaryEntries[letter].loaded : false;
                        if (!alreadyFetched) {
                            actions.getEntries({
                                letter,
                                page: 1,
                                limit: 1e3,
                                onlyActive: false,
                            });
                        }
                    }
                }}
            >
                <div>
                    {leafIcon}
                </div>

                <div className="flex justify-between gap-2 grow">
                    <div className="">{letter}</div>
                </div>

                {/*{towerCount > 0 && (*/}
                {/*    <div className="bg-brand rounded-full w-auto h-6 text-white text-center text-sm"*/}
                {/*         style={{padding: "2px 4px", minWidth: '1.5rem'}}>*/}
                {/*        <div>{towerCount}</div>*/}
                {/*    </div>*/}
                {/*)}*/}
            </div>

            <div className="region-children">
                {entriesList}
            </div>
        </div>
    )
}

LetterItem.defaultProps = {
    actions: {},
    dictionaryEntries: {},
};

LetterItem.propTypes = {
    actions: PropTypes.object,
    dictionaryEntries: PropTypes.object,
};

const mapStateToProps = state => ({
    dictionaryEntries: state.dictionaryEntries,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, dictionaryActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LetterItem);
