import React from 'react';
import PropTypes from "prop-types";
import SelectBox from "../../Utilities/Forms/SelectBox";

function EntryTypeSelection({ onChange, value }) {
    const options = [];

    options.push({
        label: '--- Choose Entry Type ---',
        value: '',
    });

    options.push({
        label: 'Prefix',
        value: 'prefix',
    });

    options.push({
        label: 'Suffix',
        value: 'suffix',
    });

    return (
        <SelectBox
            label={"Entry Type"}
            value={value}
            options={options}
            onChange={onChange}
        />
    );
}

EntryTypeSelection.defaultProps = {
    value: '',
};

EntryTypeSelection.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default EntryTypeSelection;
