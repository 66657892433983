export function getEntryTypeStr(entryType) {
    if (entryType === 'prefix') {
        return 'Prefix';
    }

    if (entryType === 'suffix') {
        return 'Suffix';
    }

    return '';
}

export function getEntryStr(entry) {
    const { entryType } = entry;
    if (entryType === 'prefix') {
        return entry.name;
    }

    if (entryType === 'suffix') {
        return `-${entry.name}`;
    }

    return entry.name;
}

export function getEntryMeaningsStr(entry) {
    if (!entry.meanings) {
        return '';
    }

    return entry.meanings.map((word) => word.word).join(', ');
}

export function getEntryWordCount(entry) {
    if (entry.wordCount) {
        return entry.wordCount;
    }

    return 0;
}

export function getEntryLanguageStr(entry) {
    if (entry.language) {
        return entry.language.name;
    }

    return '';
}
