import React, { useEffect, useRef, useState } from "react";
import WebViewer from '@pdftron/pdfjs-express-viewer';
import SiteReportFileUpload from "./SiteReportFileUpload";

function TowerSiteReportEditUpload({ mobileTower }) {
    return (
        <div>
            <div className="flex justify-center p-4">
                <SiteReportFileUpload mobileTower={mobileTower} />
            </div>
        </div>
    );
}

export default TowerSiteReportEditUpload;

