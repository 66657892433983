import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as productsActions from '../../../actions/productsActions';
import {ShoppingCartIcon} from "@heroicons/react/24/solid";
import {useNavigate} from "react-router-dom";
import {openCartPage} from "../../../utils/navigation";

function CartButton({ actions, cart, currentUser }) {
    const navigate = useNavigate();

    useEffect(() => {
        if (!cart.loaded && !cart.loading) {
            actions.getShoppingCart();
        }
    }, []);

    const goToCartPage = () => {
        openCartPage(navigate);
    };

    if (!currentUser.loggedIn) {
        return null;
    }

    let cartItemsCount = null;

    const { cartData } = cart;
    if (cartData && cartData.totalQuantity > 0) {
        cartItemsCount = (
            <div
                className="indicator-item text-sm rounded-full py-0.5 px-2 text-white bg-error cursor-pointer"
            >
                {cartData.totalQuantity}
            </div>
        );
    }

    return (
        <div>
            <div className="indicator alarms-indicator">
                {cartItemsCount}

                <button className="btn btn-ghost" onClick={goToCartPage}>
                    <ShoppingCartIcon className="w-5 h-5 inline" />
                    &nbsp;
                    CART
                </button>
            </div>
        </div>
    );
}

CartButton.defaultProps = {
    actions: {},
    currentUser: {},
    cart: {},
};

CartButton.propTypes = {
    actions: PropTypes.object,
    currentUser: PropTypes.object,
    cart: PropTypes.object,
};

const mapStateToProps = state => ({
    currentUser: state.currentUser,
    cart: state.cart,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, productsActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartButton);
