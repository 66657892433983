import React, { useEffect, lazy, Suspense } from "react";
import PropTypes from 'prop-types';
import { Route, Routes } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import AOS from "aos";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as socketActions from './actions/socketActions';
import * as usersActions from './actions/usersActions';
import * as clientInfoActions from './actions/clientInfoActions';
import ProtectedRoute from './components/Common/ProtectedRoute';
import ParameterDataFetch from "./components/Common/ParameterDataFetch";
import NotificationToaster from "./components/Common/NotificationToaster";
import EmptyLoader from "./components/Common/EmptyLoader";
import { pdfjs } from 'react-pdf';
import TowerSiteReport from "./components/MobileTowers/TowerSiteReport";
import PrefixesList from "./pages/Dictionary/PrefixesList";


pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const LocationsPage = lazy(() => import('./pages/Scada/LocationsPage'));
const LoginPage = lazy(() => import('./pages/Auth/LoginPage'));
const UserProfilePage = lazy(() => import('./pages/UserProfilePage'));
const ManageLocationsPage = lazy(() => import('./pages/Admin/LocationsPage'));
const ManageCategoriesPage = lazy(() => import('./pages/Admin/CategoriesPage'));
const ManageDevicesPage = lazy(() => import('./pages/Admin/DevicesPage'));
const ManageUsersPage = lazy(() => import('./pages/Admin/UsersPage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const ReportsPage = lazy(() => import('./pages/ReportsPage'));
const AhuViewPage = lazy(() => import('./pages/Scada/AhuViewPage'));
const DeviceViewPage = lazy(() => import('./pages/Scada/DeviceViewPage'));
const ManageNetworksPage = lazy(() => import("./pages/Admin/NetworksPage"));
const ManageRoomsPage = lazy(() => import("./pages/Admin/RoomsPage"));
const ManageAreaspage = lazy(() => import("./pages/Admin/AreasPage"));
const ManageAckMessagesPage = lazy(() => import('./pages/Admin/AckMessagesPage'));
const ManageParametersPage = lazy(() => import("./pages/Admin/ParametersPage"));
const ManageRolesPage = lazy(() => import("./pages/Admin/RolesPage"));
const ManageMobileTowersPage = lazy(() => import("./pages/Admin/MobileTowersPage"));
const ManageLicensesPage = lazy(() => import("./pages/Admin/LicensesPage"));
const ManagePostsPage = lazy(() => import('./pages/Admin/PostsPage'));
const RequestFormsPage = lazy(() => import("./pages/Dashboards/RequestFormsPage"));
const DashboardsIndexPage = lazy(() => import('./pages/Dashboards/DashboardsIndexPage'));
const AhuFilterCleaningRequestsListPage = lazy(() => import("./pages/AhuFilterCleaning/RequestsListPage"));
const AhuOnOffRequestsListPage = lazy(() => import("./pages/AhuOnOff/RequestsListPage"));
const AhuDustCollectorFilterRequestsListPage = lazy(() => import("./pages/AhuDustCollectorFilter/RequestsListPage"));
const DuctCleaningRequestsListPage = lazy(() => import("./pages/DuctCleaning/RequestsListPage"));
const AuditTrailLogReport = lazy(() => import("./pages/Reports/AuditTrailLog"));
const AlarmReport = lazy(() => import("./pages/Reports/AlarmReport"));
const EmployeesAttendancePage = lazy(() => import("./pages/Dashboards/EmployeesAttendancePage"));
const EmployeeAttendanceLog = lazy(() => import("./pages/Reports/EmployeeAttendanceLog"));
const AhuDustCollectorFilterLog = lazy(() => import("./pages/Reports/AhuDustCollectorFilterLog"));
const HydraulicTestBenchReport = lazy(() => import('./pages/Reports/HydraulicTestBenchReport'));
const GearBoxTestReport = lazy(() => import("./pages/Reports/GearBoxTestReport"));
const FileUploadPage = lazy(() => import("./pages/FileUploadPage"));
const HydraulicTestBenchPage = lazy(() => import("./pages/Scada/HydraulicTestBenchPage"));
const SamplePage = lazy(() => import("./pages/SamplePage"));
const SearchResultPage = lazy(() => import('./pages/SearchResultPage'));
const GearBoxTestPage = lazy(() => import("./pages/Scada/GearBoxTestPage"));
const PropertyPage = lazy(() => import("./pages/RealEstate/PropertyPage"));
const JobPage = lazy(() => import("./pages/JobPage"));
const CompanyPage = lazy(() => import("./pages/CompanyPage"));
const MimicPage = lazy(() => import("./pages/Scada/MimicPage"));
const ManageLimitsPage = lazy(() => import("./pages/Scada/ManageLimitsPage"));
const PortfolioAnalysisPage = lazy(() => import("./pages/MobileTowers/PortfolioAnalysisPage"));
const ManageClientInfoPage = lazy(() => import("./pages/Admin/ClientInfoPage"));
const AiChatPage = lazy(() => import("./pages/AiChatPage"));
const InterestCalculator = lazy(() => import("./pages/InterestCalculatorPage"));
const QuotationPage = lazy(() => import("./pages/QuotationPage"));
const SignupPage = lazy(() => import('./pages/Auth/SignupPage'));
const ForgotPasswordPage = lazy(() => import('./pages/Auth/ForgotPasswordPage'));
const ResetPasswordPage = lazy(() => import("./pages/Auth/ResetPasswordPage"));
const MediaFileUploadPage = lazy(() => import('./pages/MediaFileUploadPage'));
const ProductPage = lazy(() => import("./pages/Commerce/ProductPage"));
const ProductsPage = lazy(() => import('./pages/Commerce/ProductsPage'));
const PropertiesPage = lazy(() => import('./pages/RealEstate/PropertiesPage'));
const MahatmaGandhiPage = lazy(() => import('./pages/MahatmaGandhiPage'));
const CheckoutPage = lazy(() => import("./pages/Commerce/CheckoutPage"));
const CartPage = lazy(() => import('./pages/Commerce/CartPage'));
const JobsPage = lazy(() => import("./pages/JobsPage"));
const CarPage = lazy(() => import('./pages/CarPage'));
const BikePage = lazy(() => import('./pages/BikePage'));
const CoursePage = lazy(() => import('./pages/CoursePage'));
const NewsPage = lazy(() => import('./pages/NewsPage'));
const PlacePage = lazy(() => import("./pages/PlacePage"));
const TowerPage = lazy(() => import("./pages/MobileTowers/TowerPage"));
const ExamplePage = lazy(() => import("./pages/ExamplePage"));
const TenancyAnalysisPage = lazy(() => import("./pages/MobileTowers/TenancyAnalysisPage"));
const UniversitiesPage = lazy(() => import("./pages/UniversitiesPage"));
const ChartsPage = lazy(() => import("./pages/ChartsPage"));
const MaterialsPage = lazy(() => import("./pages/MaterialsPage"));
const ManagePlacesPage = lazy(() => import("./pages/ManagePlacesPage"));
const ManageCompaniesPage = lazy(() => import("./pages/ManageCompaniesPage"));
const WordsList = lazy(() => import("./pages/Dictionary/WordsList"));
const EntrySearch = lazy(() => import("./pages/Dictionary/EntrySearch"));


class App extends React.Component {
  componentDidMount() {
    this.readLocalStorage();
    // this.props.actions.initSocket();

    const { clientInfo } = this.props;
    if (!clientInfo.loaded) {
      this.props.actions.getClientInfo();
    }

    AOS.init({
      duration: 200,
    });
  }

  readLocalStorage() {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');

    let tokenJson = null;
    let userJson = null;

    if (token) {
      try {
        tokenJson = JSON.parse(token);
      } catch (err) {
        console.error(err);
      }
    }

    if (user) {
      try {
        userJson = JSON.parse(user);
      } catch (err) {
        console.error(err);
      }
    }

    if (userJson && tokenJson) {
      this.props.actions.setUserData(userJson, tokenJson);
      this.props.actions.getUserInfo();
    } else {
      this.props.actions.setUserDataUnavailable();
    }
  }

  render() {
    return (
      <div className="app-container p-0">
        <div>
          <Toaster
            containerClassName="toast-container"
            position="bottom-center"
            reverseOrder={false}
            containerStyle={{
              bottom: 100,
            }}
            toastOptions={{
              className: '',
              duration: 5000,
              style: {
                maxWidth: '840px',
              },
            }}
          />
        </div>

        <NotificationToaster />
        <ParameterDataFetch />
        <Suspense fallback={<EmptyLoader />}>
          <Routes>
            <Route exact path="/login" element={<LoginPage />} />
            <Route exact path="/login/auto/:userId" element={<LoginPage />} />

            <Route exact path="/scada/locations" element={<LocationsPage />} />
            <Route exact path="/attendance" element={<EmployeesAttendancePage />} />
            <Route exact path="/" element={<DashboardsIndexPage />} />

            <Route exact path="/dictionary/words" element={<WordsList />} />
            <Route exact path="/dictionary/entries" element={<PrefixesList />} />
            <Route exact path="/dictionary/entries/search" element={<EntrySearch />} />

            <Route exact path="/request_forms" element={<RequestFormsPage />} />
            <Route exact path="/request_forms/ahu_filter_cleaning" element={<AhuFilterCleaningRequestsListPage />} />
            <Route exact path="/request_forms/ahu_on_off" element={<AhuOnOffRequestsListPage />} />
            <Route exact path="/request_forms/ahu_dust_collector_filter_replacement" element={<AhuDustCollectorFilterRequestsListPage />} />
            <Route exact path="/request_forms/duct_cleaning" element={<DuctCleaningRequestsListPage />} />

            <Route exact path="/reports" element={<ReportsPage />} />

            <Route exact path="/manage/categories" element={<ManageCategoriesPage />} />
            <Route exact path="/manage/networks" element={<ManageNetworksPage />} />
            <Route exact path="/manage/locations" element={<ManageLocationsPage />} />
            <Route exact path="/manage/rooms" element={<ManageRoomsPage />} />
            <Route exact path="/manage/areas" element={<ManageAreaspage />} />
            <Route exact path="/manage/ack_messages" element={<ManageAckMessagesPage />} />
            <Route exact path="/manage/mobile_towers" element={<ManageMobileTowersPage />} />
            <Route exact path="/manage/devices" element={<ManageDevicesPage />} />
            {/*<Route exact path="/manage/related_devices/of/:deviceId/:deviceSlug" element={<ManageRelatedDevicesPage />} />*/}
            {/*<Route exact path="/manage/parameters/of/:deviceId/:deviceSlug" element={<ManageParametersPage />} />*/}
            <Route exact path="/manage/roles" element={<ManageRolesPage />} />
            <Route exact path="/manage/users" element={<ManageUsersPage />} />
            <Route exact path="/manage/client-info" element={<ManageClientInfoPage />} />
            <Route exact path="/manage/posts" element={<ManagePostsPage />} />

            <Route exact path="/files/upload" element={<FileUploadPage />} />

            <Route exact path="/locations/ahus/:locationId/:locationSlug" element={<AhuViewPage />} />
            <Route exact path="/devices/:deviceId/:deviceSlug" element={<DeviceViewPage />} />
            <Route exact path="/profile" element={<UserProfilePage />} />

            <Route exact path="/reports/pdf/audit_trail_log" element={<AuditTrailLogReport />} />
            <Route exact path="/reports/pdf/employee_attendance_log" element={<EmployeeAttendanceLog />} />
            <Route exact path="/reports/pdf/request_forms/ahu_dust_collector_filter" element={<AhuDustCollectorFilterLog />} />
            <Route exact path="/reports/pdf/test_bench/hydraulic_test_bench" element={<HydraulicTestBenchReport />} />
            <Route exact path="/reports/pdf/test_bench/zf_gearbox" element={<GearBoxTestReport />} />
            <Route exact path="/reports/pdf/alarms" element={<AlarmReport />} />

            <Route exact path="/towers/:towerId/:siteIdStr" element={<TowerPage />} />
            <Route exact path="/towers/:towerId/:siteIdStr/:showTab" element={<TowerPage />} />
            <Route exact path="/scada/universal-hydraulic-test-bench/:locationId" element={<HydraulicTestBenchPage />} />
            <Route exact path="/scada/gear-box-test/:locationId" element={<GearBoxTestPage />} />

            <Route exact path="/page/samplepage" element={<SamplePage />} />

            <Route path="/search/:q" element={<SearchResultPage />} />
            <Route exact path="/jobs/:jobId/:jobSlug" element={<JobPage />} />
            <Route exact path="/page/companypage" element={<CompanyPage />} />
            <Route exact path="/scada/mimicpage" element={<MimicPage />} />
            <Route exact path="/scada/manage-limits" element={<ManageLimitsPage />} />
            <Route exact path="/page/mobiletowersportfoliopage" element={<PortfolioAnalysisPage />} />
            <Route exact path="/page/mobiletowerstenancypage" element={<TenancyAnalysisPage />} />

            <Route exact path="/aichat" element={<AiChatPage />} />
            <Route exact path="/manage/licenses" element={<ManageLicensesPage />} />
            <Route exact path="/page/interest-calculator" element={<InterestCalculator />} />
            <Route exact path="/page/quotation" element={<QuotationPage />} />
            <Route exact path="/signup" element={<SignupPage />} />
            <Route exact path="/forgot_password" element={<ForgotPasswordPage />} />
            <Route exact path="/reset_password" element={<ResetPasswordPage />} />
            <Route exact path="/media_file_upload" element={<MediaFileUploadPage />} />

            <Route exact path="/products" element={<ProductsPage />} />
            <Route exact path="/product/:productId/:productSlug" element={<ProductPage />} />
            <Route exact path="/cart" element={<CartPage />} />
            <Route exact path='/checkout' element={<CheckoutPage />} />

            <Route exact path="/properties" element={<PropertiesPage />} />
            <Route exact path="/property/:propertyId/:propertySlug" element={<PropertyPage />} />
            <Route exact path="/jobs" element={<JobsPage />} />

            <Route exact path='/car' element={<CarPage />} />
            <Route exact path="/bike" element={<BikePage />} />
            <Route exact path="/course" element={<CoursePage />} />
            <Route exact path="/news" element={<NewsPage />} />
            <Route exact path="/place" element={<PlacePage />} />

            <Route exact path="/example" element={<ExamplePage />} />
            <Route exact path="/universities" element={<UniversitiesPage />} />
            <Route exact path="/materials" element={<MaterialsPage />} />
            <Route exact path="/charts" element={<ChartsPage />} />
            <Route exact path="/manage/places" element={<ManagePlacesPage />} />
            <Route exact path="/manage/companies" element={<ManageCompaniesPage />} />

            <Route exact path="/mahatma-gandhi" element={<MahatmaGandhiPage />} />
            <Route default component={NotFoundPage} />
          </Routes>
        </Suspense>
      </div>
    );
  }
}

App.defaultProps = {
  actions: {},
  clientInfo: {},
  currentUser: {},
  socket: {},
};

App.propTypes = {
  actions: PropTypes.object,
  clientInfo: PropTypes.object,
  currentUser: PropTypes.object,
  socket: PropTypes.object,
};

const mapStateToProps = state => ({
  clientInfo: state.clientInfo,
  currentUser: state.currentUser,
  socket: state.socket,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({},
    socketActions, usersActions, clientInfoActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
