import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  UPDATE_WORD_STARTING,
  UPDATE_WORD_SUCCESS,
  UPDATE_WORD_ERROR,
} from '../../constants/actionTypes';

export default function updateWordReducer(state = initialState.updateDictionaryWord, action) {
  switch (action.type) {
    case UPDATE_WORD_STARTING: {
      const {wordId} = action;
      const newState = clone(state);

      if (!newState[wordId]) {
        newState[wordId] = clone(initialState.updateDictionaryWord.wordId);
      }

      const updateData = newState[wordId];
      updateData.saving = true;
      updateData.saved = false;
      updateData.error = null;

      return newState;
    }

    case UPDATE_WORD_SUCCESS: {
      const {wordId} = action;
      const newState = clone(state);

      if (!newState[wordId]) {
        newState[wordId] = clone(initialState.updateDictionaryWord.wordId);
      }

      const updateData = newState[wordId];
      updateData.saving = false;
      updateData.saved = true;
      updateData.error = null;
      return newState;
    }

    case UPDATE_WORD_ERROR: {
      const {wordId} = action;
      const newState = clone(state);

      if (!newState[wordId]) {
        newState[wordId] = clone(initialState.updateDictionaryWord.wordId);
      }

      const updateData = newState[wordId];
      updateData.saving = false;
      updateData.saved = false;
      updateData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
