import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  DELETE_ENTRY_STARTING,
  DELETE_ENTRY_SUCCESS,
  DELETE_ENTRY_ERROR,
} from '../../constants/actionTypes';

export default function deleteEntryReducer(state = initialState.deleteDictionaryEntry, action) {
  switch (action.type) {
    case DELETE_ENTRY_STARTING: {
      const {entryId} = action;
      const newState = clone(state);

      if (!newState[entryId]) {
        newState[entryId] = clone(initialState.deleteDictionaryEntry.entryId);
      }

      const deleteData = newState[entryId];
      deleteData.saving = true;
      deleteData.saved = false;
      deleteData.error = null;

      return newState;
    }

    case DELETE_ENTRY_SUCCESS: {
      const {entryId} = action;
      const newState = clone(state);

      if (!newState[entryId]) {
        newState[entryId] = clone(initialState.deleteDictionaryEntry.entryId);
      }

      const deleteData = newState[entryId];
      deleteData.saving = false;
      deleteData.saved = true;
      deleteData.error = null;
      return newState;
    }

    case DELETE_ENTRY_ERROR: {
      const {entryId} = action;
      const newState = clone(state);

      if (!newState[entryId]) {
        newState[entryId] = clone(initialState.deleteDictionaryEntry.entryId);
      }

      const deleteData = newState[entryId];
      deleteData.saving = false;
      deleteData.saved = false;
      deleteData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
