import React, { useState } from 'react';
import TextInput from '../Utilities/Forms/TextInput';
import { BiSearch } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import { openSearchResult } from '../../../utils/navigation';
import Autosuggest from 'react-autosuggest';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as searchActions from '../../../actions/searchActions';
import {toHumanFormat} from "../../../utils/numbers";
import {
    FaAlignLeft,
    FaBook,
    FaBuilding,
    FaCalculator, FaCamera,
    FaExternalLinkAlt,
    FaFileAlt, FaFileWord, FaFilm,
    FaMapMarker, FaQuestionCircle,
    FaSearch,
    FaUserAlt, FaVideo
} from "react-icons/fa";
import {FaFileLines, FaFileWaveform} from "react-icons/fa6";
import {BsThreeDots} from "react-icons/bs";

function SearchHeader({ actions, searchSuggestions }) {
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    const onSearchClicked = () => {
        openSearchResult(navigate, searchQuery);
    };

    const onSuggestionSelected = (event, { suggestionValue }) => {
        setSearchQuery(suggestionValue);
        openSearchResult(navigate, suggestionValue);
    };

    const inputProps = {
        value: searchQuery,
        placeholder: 'Enter search query',
        onChange: (e) => {
            setSearchQuery(e.target.value);
        },
    };

    const onSuggestionsFetchRequested = (data) => {
        actions.getSearchSuggestions(data.value);
    };

    const onSuggestionsClearRequested = () => { };
    const getSuggestionValue = (suggestion) => suggestion;
    const suggestions = searchSuggestions[searchQuery] ? searchSuggestions[searchQuery].suggestions : [];

    const renderSuggestion = (suggestion) => {
        const result = searchSuggestions[searchQuery].resultsData[suggestion];
        const searchCountText = toHumanFormat(result.searchCount);

        let suggestionIcon = null;
        switch (result.resultType) {
            case 'alias': {
                suggestionIcon = (<FaSearch className="w-3 h-3" />);
                break;
            }

            case 'expression': {
                suggestionIcon = (<FaCalculator className="w-3 h-3" />);
                break;
            }

            case 'meta_search': {
                suggestionIcon = (<FaSearch className="w-3 h-3" />);
                break;
            }

            case 'redirect': {
                suggestionIcon = (<FaExternalLinkAlt className="w-3 h-3" />)
                break;
            }

            case 'person': {
                suggestionIcon = (<FaUserAlt className="w-3 h-3" />);
                break;
            }

            case 'files': {
                suggestionIcon = (<FaFileAlt className="w-3 h-3" />);
                break;
            }

            case 'company': {
                suggestionIcon = (<FaBuilding className="w-3 h-3" />);
                break;
            }

            case 'place': {
                suggestionIcon = (<FaMapMarker className="w-3 h-3" />);
                break;
            }

            case 'book': {
                suggestionIcon = (<FaBook className="w-3 h-3" />);
                break;
            }

            case 'movie': {
                suggestionIcon = (<FaFilm className="w-3 h-3" />);
                break;
            }

            case 'dictionary_prefix': {
                suggestionIcon = (<BsThreeDots className="w-3 h-3" />);
                break;
            }

            case 'dictionary_word': {
                suggestionIcon = (<FaFileWord className="w-3 h-3" />);
                break;
            }

            case 'video': {
                suggestionIcon = (<FaVideo className="w-3 h-3" />);
                break;
            }
            case 'fixed_text': {
                suggestionIcon = (<FaAlignLeft className="w-3 h-3" />);
                break;
            }

            case 'photos_timeline': {
                suggestionIcon = (<FaCamera className="w-3 h-3" />);
                break;
            }

            case 'blog_article': {
                suggestionIcon = (<FaFileLines className="w-3 h-3" />);
                break;
            }

            default: {
                suggestionIcon = (<FaQuestionCircle className="w-3 h-3" />);
                break;
            }
        }
        
        return (
            <div className="suggestion-row flex flex-row items-center">
                <div className="basis-auto mr-2">
                    {suggestionIcon}
                </div>
                
                <div className="grow">{suggestion}</div>
                <div className="basis-auto ml-auto ml-2">
                    {searchCountText}
                </div>
            </div>
        );
    };

    return (
        <div className="p-2 text-black hidden md:flex md:flex-row md:w-80 xl:w-96">
            {/* <TextInput
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className=""
                label=""             
                helperText=""
                placeholder="Enter search query"
            /> */}

            <Autosuggest
                id="searchBox"
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                onSuggestionSelected={onSuggestionSelected}
            />

            <span className='search-icon'></span>

            <div className=''>
                <button className='btn text-white' onClick={onSearchClicked}>
                    <BiSearch className='w-6 h-6' />
                </button>
            </div>
        </div>
    );
}

SearchHeader.defaultProps = {
    actions: {},
    searchSuggestions: {},
};

SearchHeader.propTypes = {
    actions: PropTypes.object,
    searchSuggestions: PropTypes.object,
};

const mapStateToProps = state => ({
    searchSuggestions: state.searchSuggestions,

});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, searchActions), dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(SearchHeader);

