import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as dictionaryActions from "../../../../actions/dictionaryActions";
import SelectBox from "../../Utilities/Forms/SelectBox";

function LanguageSelection({ actions, label, languages, placeholder, onChange, value, ...moreProps }) {
    const [languagesRequested, setLanguagesRequested] = useState(false);

    useEffect(() => {
        if (!languages.loaded && !languagesRequested) {
            setLanguagesRequested(true);
            actions.getLanguages(true);
        }
    }, []);

    const options = [];

    options.push({
        label: placeholder,
        value: 0,
    });

    if (languages && languages.ids.length) {
        languages.ids.forEach((languageId) => {
            const language = languages.map[languageId];

            options.push({
                label: language.name,
                value: languageId,
            })
        });
    }

    return (
        <SelectBox
            label={label}
            value={value}
            options={options}
            onChange={onChange}
            {...moreProps}
        />
    );
}

LanguageSelection.defaultProps = {
    actions: {},
    label: '',
    languages: {},
    placeholder: '--- Choose Language ---',
    value: 0,
};

LanguageSelection.propTypes = {
    actions: PropTypes.object,
    label: PropTypes.string,
    languages: PropTypes.object,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    languages: state.languages,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, dictionaryActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelection);
