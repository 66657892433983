import {
    ADD_MOBILE_TOWER_IMAGE_STARTING,
    ADD_MOBILE_TOWER_IMAGE_SUCCESS,
    ADD_MOBILE_TOWER_IMAGE_ERROR,

    DELETE_MOBILE_TOWER_IMAGE_STARTING,
    DELETE_MOBILE_TOWER_IMAGE_SUCCESS,
    DELETE_MOBILE_TOWER_IMAGE_ERROR,

    ADD_IMAGE_LABEL_STARTING,
    ADD_IMAGE_LABEL_SUCCESS,
    ADD_IMAGE_LABEL_ERROR,

    ADD_TOWER_CHECKLIST_STARTING,
    ADD_TOWER_CHECKLIST_SUCCESS,
    ADD_TOWER_CHECKLIST_ERROR,

    ADD_TOWER_SITEREPORT_STARTING,
    ADD_TOWER_SITEREPORT_SUCCESS,
    ADD_TOWER_SITEREPORT_ERROR,

} from '../../constants/actionTypes';
import axios from '../../axios';
import {
    ADD_MOBILE_TOWER_IMAGE,
    UPDATE_MOBILE_TOWER_IMAGE,
    DELETE_MOBILE_TOWER_IMAGE,
    ADD_IMAGE_LABEL,
    ADD_TOWER_CHECKLIST,
    ADD_TOWER_SITEREPORT,

} from '../../constants/apiUrls';


export function addMobileTowerImageStarting(mobileTowerId) {
    return { type: ADD_MOBILE_TOWER_IMAGE_STARTING, mobileTowerId };
}

export function addMobileTowerImageSuccess(mobileTowerId, data) {
    return { type: ADD_MOBILE_TOWER_IMAGE_SUCCESS, mobileTowerId, data };
}

export function addMobileTowerImageError(mobileTowerId, err) {
    return { type: ADD_MOBILE_TOWER_IMAGE_ERROR, mobileTowerId, err };
}

export function addMobileTowerImage(mobileTowerId, fileId, imageLabel) {
    return (dispatch) => {
        dispatch(addMobileTowerImageStarting());

        axios.post(ADD_MOBILE_TOWER_IMAGE, {
            mobileTowerId,
            fileId,
            imageLabel,
        }).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(addMobileTowerImageSuccess(mobileTowerId, response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to upload Tower Image';
            dispatch(addMobileTowerImageError(mobileTowerId, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(addMobileTowerImageError(mobileTowerId, error));
        });
    };
}

export function deleteMobileTowerImageStarting(mobileTowerId, imageId) {
    return { type: DELETE_MOBILE_TOWER_IMAGE_STARTING, mobileTowerId, imageId };
}

export function deleteMobileTowerImageSuccess(mobileTowerId, imageId, data) {
    return { type: DELETE_MOBILE_TOWER_IMAGE_SUCCESS, mobileTowerId, imageId, data };
}

export function deleteMobileTowerImageError(mobileTowerId, imageId, err) {
    return { type: DELETE_MOBILE_TOWER_IMAGE_ERROR, mobileTowerId, imageId, err };
}

export function deleteMobileTowerImage(mobileTowerId, imageId) {
    return (dispatch) => {
        dispatch(deleteMobileTowerImageStarting(mobileTowerId, imageId));
        axios.delete(DELETE_MOBILE_TOWER_IMAGE, {
            data: {
                imageId,
                mobileTowerId,
            },
        }).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(deleteMobileTowerImageSuccess(mobileTowerId, imageId, response.data));
            } else {
                const err = (response && response.data && response.data.error) ?
                    response.data.error : 'Failed to delete tower image';
                dispatch(deleteMobileTowerImageError(mobileTowerId, imageId, err));
            }
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(deleteMobileTowerImageError(mobileTowerId, imageId, error));
        });
    };
}

export function addImageLabelStarting(imageLabel) {
    return { type: ADD_IMAGE_LABEL_STARTING, imageLabel };
}

export function addImageLabelSuccess(imageLabel, data) {
    return { type: ADD_IMAGE_LABEL_SUCCESS, imageLabel, data };
}

export function addImageLabelError(imageLabel, err) {
    return { type: ADD_IMAGE_LABEL_ERROR, imageLabel, err };
}

export function addImageLabel(mobileTowerId, fileId, imageLabel, category) {
    return (dispatch) => {
        dispatch(addImageLabelStarting());
        axios.post(ADD_IMAGE_LABEL, {
            mobileTowerId,
            fileId,
            imageLabel,
            category,
        }).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(addImageLabelSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to Image Label';
            dispatch(addImageLabelError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(addImageLabelError(error));
        });
    };
}

export function addTowerChecklistStarting(mobileTowerId) {
    return { type: ADD_TOWER_CHECKLIST_STARTING, mobileTowerId };
}

export function addTowerChecklistSuccess(mobileTowerId, data) {
    return { type: ADD_TOWER_CHECKLIST_SUCCESS, mobileTowerId, data };
}

export function addTowerChecklistError(mobileTowerId, err) {
    return { type: ADD_TOWER_CHECKLIST_ERROR, mobileTowerId, err };
}

export function addTowerChecklist(mobileTowerId, fileId) {
    return (dispatch) => {
        dispatch(addTowerChecklistStarting(mobileTowerId));

        axios.post(ADD_TOWER_CHECKLIST, {
            mobileTowerId,
            fileId,
        }).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(addTowerChecklistSuccess(mobileTowerId, response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to upload Tower Checklist';
            dispatch(addTowerChecklistError(mobileTowerId, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(addTowerChecklistError(mobileTowerId, error));
        });
    };
}

export function addTowerSiteReportStarting(mobileTowerId) {
    return { type: ADD_TOWER_SITEREPORT_STARTING, mobileTowerId };
}

export function addTowerSiteReportSuccess(mobileTowerId, data) {
    return { type: ADD_TOWER_SITEREPORT_SUCCESS, mobileTowerId, data };
}

export function addTowerSiteReportError(mobileTowerId, err) {
    return { type: ADD_TOWER_SITEREPORT_ERROR, mobileTowerId, err };
}

export function addTowerSiteReport(mobileTowerId, fileId) {
    return (dispatch) => {
        dispatch(addTowerSiteReportStarting(mobileTowerId));

        axios.post(ADD_TOWER_SITEREPORT, {
            mobileTowerId,
            fileId,
        }).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(addTowerSiteReportSuccess(mobileTowerId, response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to upload Tower Site Report';
            dispatch(addTowerSiteReportError(mobileTowerId, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(addTowerSiteReportError(mobileTowerId, error));
        });
    };
}
