import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as loginActions from '../../../actions/loginActions';
import {ChevronDownIcon, KeyIcon, LockClosedIcon} from '@heroicons/react/24/solid';
import {Link} from 'react-router-dom';

function UserDetails({actions, currentUser}) {
    const logoutUser = () => {
        actions.logoutUser(0);
    };

    if (!currentUser.loggedIn) {
        return null;
    }

    const userFullName = currentUser.userData.fullname;

    return (
        <div className="user-details dropdown z-40 dropdown-hover dropdown-end rounded-lg">
            <div className="m-1 btn btn-ghost px-0">
                <div className="avatar">
                    <div className="rounded-full w-10 h-10 m-1">
                        <img src="/img/avatar.jpg"/>
                    </div>
                </div>

                <div className="ml-2">
                    <span className="hidden md:inline">{userFullName}</span>
                    <ChevronDownIcon className="font-bold text-danger w-6 h-5 inline ml-2"/>
                </div>
            </div>

            <ul className="p-0 text-black shadow menu dropdown-content bg-base-100 rounded-sm w-64">
                <li className="text-sm">
                    <Link
                        to="/profile"
                        className="flex items-center p-2 text-base font-normal rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                        <KeyIcon className="w-4 h-4 inline mr-1"/>
                        Change Password
                    </Link>
                </li>

                {/*<li className="text-sm">*/}
                {/*  <a>Logout (Notifications)</a>*/}
                {/*</li>*/}

                <li className="text-sm">
                    <a
                        href={null}
                        onClick={logoutUser}
                        className="flex items-center p-2 text-base font-normal rounded-lg hover:text-white hover:bg-rose-500 dark:hover:bg-rose-500"

                    >
                        <LockClosedIcon className="w-4 h-4 inline mr-1"/>
                        Logout
                    </a>
                </li>
            </ul>
        </div>
    );
}

UserDetails.defaultProps = {
    actions: {},
    currentUser: {},
};

UserDetails.propTypes = {
    actions: PropTypes.object,
    currentUser: PropTypes.object,
};

const mapStateToProps = state => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, loginActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
