import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Link} from 'react-router-dom';
import {HomeIcon, UserGroupIcon, UsersIcon} from "@heroicons/react/24/solid";
import {TableCellsIcon} from "@heroicons/react/24/outline";
import {FaIndustry} from 'react-icons/fa';
import {FaTowerCell} from 'react-icons/fa6';
import {VscRequestChanges} from 'react-icons/vsc';
import {TbReportAnalytics, TbCategory} from 'react-icons/tb';
import {PiShareNetworkBold} from 'react-icons/pi';
import {GrOrganization} from 'react-icons/gr';
import {MdOutlineLocationCity, MdOutlineMeetingRoom} from 'react-icons/md';
import {BiMessageAltDetail} from 'react-icons/bi';
import {RiDeviceLine} from 'react-icons/ri';

// import * as actionsActions from '../actions/actions';

function SideNavMenu({currentUser, toggleSideNav}) {
    const { rolePermissions } = currentUser;

    const showManageUsers = (rolePermissions['users.view'] === 1);
    const showManageRoles = (rolePermissions['roles.view'] === 1);
    const showManageCategories = (rolePermissions['categories.view'] === 1);
    const showManageNetworks = (rolePermissions['networks.view'] === 1);
    const showManageAreas = (rolePermissions['areas.view'] === 1);
    const showManageLocations = (rolePermissions['locations.view'] === 1);
    const showManageRooms = (rolePermissions['rooms.view'] === 1);
    const showManageDevices = (rolePermissions['devices.view'] === 1);
    const showManageAckMessages = (rolePermissions['ack_messages.view'] === 1);
    const showManageLicenses = (rolePermissions['licenses.view'] === 1);
    const showManageMobileTowers = (rolePermissions['mobile_towers.view'] === 1);

    return (
        <div className="container mx-auto">
            <aside className="w-64 fixed mt-16 -ml-3 z-50" aria-label="Sidebar">
                <div className="px-3 py-4 overflow-y-auto rounded bg-gray-50 dark:bg-gray-800">
                    <ul className="space-y-2">
                        <li>
                            <Link
                                to="/"
                                className="flex items-center p-2 text-base font-normal rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                                onClick={toggleSideNav}
                            >
                                <HomeIcon className="w-4 h-4 mr-1 inline"/>
                                Home
                            </Link>
                        </li>

                        {/*<li>*/}
                        {/*    <Link*/}
                        {/*        to="/scada/locations"*/}
                        {/*        className="flex items-center p-2 text-base font-normal rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"*/}
                        {/*        onClick={toggleSideNav}*/}
                        {/*        >*/}
                        {/*        <FaIndustry className="w-4 h-4 mr-1 inline"/>*/}
                        {/*        SCADA Home*/}
                        {/*    </Link>*/}
                        {/*</li>*/}

                        {/*<li>*/}
                        {/*    <Link*/}
                        {/*        to="/request_forms"*/}
                        {/*        className="flex items-center p-2 text-base font-normal rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"*/}
                        {/*        onClick={toggleSideNav}*/}
                        {/*        >*/}
                        {/*        <VscRequestChanges className="w-4 h-4 mr-1 inline"/>*/}
                        {/*        Request Forms*/}
                        {/*    </Link>*/}
                        {/*</li>*/}

                        {/*<li>*/}
                        {/*    <Link*/}
                        {/*        to="/towers/1/22"*/}
                        {/*        className="flex items-center p-2 text-base font-normal rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"*/}
                        {/*        onClick={toggleSideNav}*/}
                        {/*    >*/}
                        {/*        <FaTowerCell className="w-4 h-4 mr-1 inline"/>*/}
                        {/*        Mobile Tower*/}
                        {/*    </Link>*/}
                        {/*</li>*/}


                        <li>
                            <Link
                                to="/reports"
                                className="flex items-center p-2 text-base font-normal rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                                onClick={toggleSideNav}
                                >
                                <TbReportAnalytics className="w-4 h-4 mr-1 inline"/>
                                Reports
                            </Link>
                        </li>

                        {showManageUsers && (
                            <li>
                                <Link
                                    to="/manage/users"
                                    className="flex items-center p-2 text-base font-normal rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                                    onClick={toggleSideNav}
                                >
                                    <UsersIcon className="w-4 h-4 mr-1 inline"/>
                                    Users
                                </Link>
                            </li>
                        )}

                        {showManageRoles && (
                            <li>
                                <Link
                                    to="/manage/roles"
                                    className="flex items-center p-2 text-base font-normal rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                                    onClick={toggleSideNav}
                                >
                                    <UserGroupIcon className="w-4 h-4 mr-1 inline"/>
                                    User Roles
                                </Link>
                            </li>
                        )}

                        {showManageCategories && (
                            <li>
                                <Link
                                    to="/manage/categories"
                                    className="flex items-center p-2 text-base font-normal rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                                    onClick={toggleSideNav}
                                    >
                                    <TbCategory className="w-4 h-4 mr-1 inline"/>
                                    Categories
                                </Link>
                            </li>
                        )}

                        {showManageNetworks && (
                            <li>
                              <Link to="/manage/networks"
                                  className="flex items-center p-2 text-base font-normal rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                                  onClick={toggleSideNav}
                                  >
                                  <PiShareNetworkBold className="w-4 h-4 mr-1 inline"/>
                                  Manage Networks
                              </Link>
                            </li>
                        )}

                        {showManageAreas && (
                            <li>
                                <Link to="/manage/areas"
                                    className="flex items-center p-2 text-base font-normal rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                                    onClick={toggleSideNav}
                                    >
                                    <GrOrganization className="w-4 h-4 mr-1 inline"/>
                                    Manage Areas
                                </Link>
                            </li>
                        )}

                        {showManageLocations && (
                            <li>
                              <Link to="/manage/locations"
                                  className="flex items-center p-2 text-base font-normal rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                                  onClick={toggleSideNav}
                                  >
                                  <MdOutlineLocationCity className="w-4 h-4 mr-1 inline"/>
                                  Manage Locations
                              </Link>
                            </li>
                        )}

                        {showManageRooms && (
                            <li>
                              <Link to="/manage/rooms"
                                  className="flex items-center p-2 text-base font-normal rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                                  onClick={toggleSideNav}
                                  >
                                  <MdOutlineMeetingRoom className="w-4 h-4 mr-1 inline"/>
                                  Manage Rooms
                              </Link>
                            </li>
                        )}

                        {showManageDevices && (
                            <li>
                              <Link to="/manage/devices"
                                  className="flex items-center p-2 text-base font-normal rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                                  onClick={toggleSideNav}
                                  >
                                  <RiDeviceLine className="w-4 h-4 mr-1 inline"/>
                                  Manage Devices
                              </Link>
                            </li>
                        )}

                        {showManageAckMessages && (
                            <li>
                                <Link to="/manage/ack_messages"
                                    className="flex items-center p-2 text-base font-normal rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                                    onClick={toggleSideNav}
                                    >
                                    <BiMessageAltDetail className="w-4 h-4 mr-1 inline"/>
                                    Manage Ack Messages
                                </Link>
                            </li>
                        )}

                        {showManageLicenses && (
                            <li>
                                <Link to="/manage/licenses"
                                    className="flex items-center p-2 text-base font-normal rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                                    onClick={toggleSideNav}
                                    >
                                    <BiMessageAltDetail className="w-4 h-4 mr-1 inline"/>
                                    Manage License
                                </Link>
                            </li>
                        )}

                        {showManageMobileTowers && (
                            <li>
                                <Link to="/manage/mobile_towers"
                                    className="flex items-center p-2 text-base font-normal rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                                    onClick={toggleSideNav}
                                    >
                                    Manage Mobile Towers
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </aside>

            {/*<div*/}
            {/*    className="w-full h-full overlay bg-gray-500 opacity-40 mt-16"*/}
            {/*    onClick={toggleSideNav}*/}
            {/*/>*/}
        </div>
    );
}

SideNavMenu.defaultProps = {
    actions: {},
    currentUser: {},
};

SideNavMenu.propTypes = {
    actions: PropTypes.object,
    currentUser: PropTypes.object,
};

const mapStateToProps = state => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideNavMenu);
