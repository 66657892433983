import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    GET_LANGUAGES_ERROR,
    GET_LANGUAGES_STARTING,
    GET_LANGUAGES_SUCCESS,
} from '../../constants/actionTypes';

export default function languagesReducer(state = initialState.languages, action) {
    switch (action.type) {
        case GET_LANGUAGES_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

    case GET_LANGUAGES_SUCCESS: {
        const newState = cloneDeep(state);
        const ids = [];
        const map = {};

        action.data.languages.forEach((language) => {
            const languageId = language.id;
            ids.push(languageId);
            map[languageId] = language;
        });

        newState.ids = ids;
        newState.map = map;
        newState.loading = false;
        newState.loaded = true;

        return newState;
    }

    case GET_LANGUAGES_ERROR: {
        const newState = cloneDeep(state);
        newState.loading = false;
        newState.loaded = false;
        newState.error = action.err ? action.err : 'Failed to retrieve languages.';
        return newState;
    }

    default: {
        return state;
    }
  }
}
