import slugify from 'slugify';
import {apiUrl} from "../constants/appConfig";

export function getQueryParamsFromEncodedUrl(url) {
  if(!url) url = window.location.search;
  var query = url.substr(1);
  var result = {};
  query.split("&").forEach(function(part) {
    var item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });

  if (!result.p) {
    return {};
  }

  query = result.p;
  result = JSON.parse(atob(query));
  return result;
}

export function openLocation(navigate, location) {
  if (location.uiTemplate === 'ahus') {
    navigate(`/locations/ahus/${location.id}/${slugify(location.name.toLowerCase())}`);
  } else if (location.uiTemplate === 'hydraulic_testing') {
    navigate(`/scada/universal-hydraulic-test-bench/${location.id}`);
  } else if (location.uiTemplate === 'gearbox_test') {
    navigate(`/scada/gear-box-test/${location.id}`);
  } else {
    navigate(`/scada/ui-template-not-found/${location.id}/${slugify(location.name.toLowerCase())}`);
  }
}

export function openDashboard(navigate) {
  navigate('/');
}

export function openScadaHome(navigate) {
  navigate('/scada/locations');
}

export function openDevicePage(navigate, device) {
  navigate(`/devices/${device.id}/${slugify(device.displayName.toLowerCase())}`);
}

export function openManageRelatedDevicesPage(navigate, device) {
  navigate(`/manage/related_devices/of/${device.id}/${slugify(device.displayName.toLowerCase())}`);
}

export function openManageParametersPage(navigate, device) {
  navigate(`/manage/parameters/of/${device.id}/${slugify(device.displayName.toLowerCase())}`);
}

export function openNewRequestPage_AhuFilterCleaning(navigate) {
  navigate('/request_forms/ahu_filter_cleaning/new');
}

export function openRequestsListPage_AhuFilterCleaning(navigate) {
  navigate('/request_forms/ahu_filter_cleaning');
}

export function getFileUri(fileId) {
  return `${apiUrl}/media/${fileId}`;
}

export function getThumbUri(fileId, dimens) {
  return `${apiUrl}/media/${fileId}/resize/${dimens}`;
}

export function getDownloadUri(fileId) {
  return `${apiUrl}/media/${fileId}/download`;
}

export function goToTowerPage(navigate, mobileTower) {
  navigate(`/towers/${mobileTower.id}/${slugify(mobileTower.siteIdStr)}`);
}

export function openSearchResult(navigate, query) {
  navigate(`/search/${query}`);
}

export function openLogin(navigate) {
  navigate(`/login`);
}

export function openPropertyPage(navigate, property) {
  navigate(`/property/${property.id}/${slugify(property.name.toLowerCase())}`);
}

export function openProductsPage(navigate) {
  navigate('/products');
}

export function openProductPage(navigate, product) {
  navigate(`/product/${product.id}/${slugify(product.name.toLowerCase())}`);
}

export function openCartPage(navigate) {
  navigate('/cart');
}

export function openCheckoutPage(navigate) {
  navigate('/checkout');
}

export function openJobPage(navigate, job) {
  navigate(`/jobs/${job.id}/${slugify(job.title.toLowerCase())}`);
}
