import React, { useState } from "react";
import RadioButton from "./Utilities/Forms/RadioButton";
import TextInput from "./Utilities/Forms/TextInput";
import Modal from "./Utilities/Modal";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getFormattedDateStr } from "../../utils/general";
import {
  getAlarmNotificationTitle,
  getAlarmNotificationIcon,
} from "../../utils/notifications";
import AuthPasswordModal from "./AuthPasswordModal";
import * as notificationsActions from '../../actions/notificationsActions';

function AlarmAckModal({ actions, alarm, toggleModal }) {
  const [showAuthPassword, setShowAuthPassword] = useState(false);
  const [remark, setRemark] = useState('');

  let modalTitle;
  modalTitle = `Acknowledge Alarm`;

  const createdAt = getFormattedDateStr(alarm.createdAt);
  const notificationTitle = getAlarmNotificationTitle(alarm);
  const notificationIcon = getAlarmNotificationIcon(alarm);
  const buttonsConfig = [];

  buttonsConfig.push({
    label: "Acknowledge",
    className: "btn-primary px-8",
    onClick: () => {
      // Show auth password dialog
      setShowAuthPassword(true);
    },
  });

  const toggleAuthPasswordModal = () => {
    setShowAuthPassword(false);
  };

  let authPasswordModal = null;
  if (showAuthPassword) {
    authPasswordModal = (
      <AuthPasswordModal
        saving={false}
        onSubmit={(authPassword, authComments) => {
          const postData = {
            authPassword,
            authComments,
            remark,
          };

          actions.acknowledgeAlarm(alarm.id, postData);
        }}
        toggleModal={toggleAuthPasswordModal}
      />
    );
  }

  return (
    <Modal
      buttons={buttonsConfig}
      title={modalTitle}
      className="modal-xl"
      toggleModal={toggleModal}
    >
      <div className="text-black">
        {notificationTitle} at {createdAt}
      </div>

      <RadioButton
        label="Assignable"
        checked=""
        helperText=""
        onChange={(e) => e.target.checked}
      />

      <RadioButton
        label="Non-Assignable"
        checked=""
        helperText=""
        onChange={(e) => e.target.checked}
      />

      <TextInput
        label=""
        helperText="Please enter a remark for this alarm, for example why it occured and how it was resolved."
        placeholder="Enter Assignable Remarks"
        value={remark}
        onChange={(e) => setRemark(e.target.value)}
      />

      {authPasswordModal}
    </Modal>
  );
}

AlarmAckModal.defaultProps = {
  actions: {},
};

AlarmAckModal.propTypes = {
  actions: PropTypes.object,
  toggleModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Object.assign({}, notificationsActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlarmAckModal);
