import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as appViewActions from '../../../actions/appViewActions';

function goFullScreen() {
  var el = document.documentElement,
    rfs = el.requestFullscreen
      || el.webkitRequestFullScreen
      || el.mozRequestFullScreen
      || el.msRequestFullscreen
    ;

  rfs.call(el);
}

function exitFullScreen() {
  var el = document,
    rfs = el.exitFullscreen
      || el.msExitFullscreen
      || el.mozCancelFullScreen
      || el.webkitExitFullscreen
    ;

  rfs.call(el);
}

function ToggleFullScreen({ actions, fullScreenStatus }) {
  const [isFullScreen, setFullScreen] = useState(false);

  const enableFullScreen = () => {
    //actions.enableFullScreen();
    if (isFullScreen) {
      exitFullScreen();
      setFullScreen(false);
    } else {
      goFullScreen();
      setFullScreen(true);
    }
  };

  return (
    <div>
      <button className="btn btn-square btn-ghost" onClick={enableFullScreen}>
        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6"
          viewBox="0 0 24 24" fill="#FFFFFF">
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z" />
        </svg>
      </button>
    </div>
  );
}

ToggleFullScreen.defaultProps = {
  actions: {},
  fullScreenStatus: {},
};

ToggleFullScreen.propTypes = {
  actions: PropTypes.object,
  fullScreenStatus: PropTypes.object,
};

const mapStateToProps = state => ({
  fullScreenStatus: state.fullScreenStatus,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, appViewActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ToggleFullScreen);
