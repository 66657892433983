import React from 'react';
import PropTypes from 'prop-types';
import AlarmsDrawer from './AlarmsDrawer';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as notificationsActions from '../../../actions/notificationsActions';

class Alarms extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showAlarms: false,
        };

        this.toggleAlarmsDrawer = this.toggleAlarmsDrawer.bind(this);
    }

    toggleAlarmsDrawer() {
        this.setState({
            showAlarms: !this.state.showAlarms,
        });
    }

    render() {
        const {showAlarms} = this.state;
        const {requestNotifications} = this.props;
        const totalNotificationsCount = requestNotifications.ids ? requestNotifications.ids.length : 0;

        let alarmsDrawer = null;
        if (showAlarms) {
            alarmsDrawer = (
                <AlarmsDrawer toggleDrawer={this.toggleAlarmsDrawer}/>
            );
        }

        let notificationsCount = null;

        if (totalNotificationsCount) {
            notificationsCount = (
                <div
                    className="indicator-item text-sm rounded-full py-0.5 px-2 text-white bg-error animate-pulse cursor-pointer"
                    onClick={this.toggleAlarmsDrawer}
                >
                    {totalNotificationsCount}
                </div>
            );
        }

        return (
            <div>
                <div className="indicator alarms-indicator">
                    {notificationsCount}

                    <button className="btn btn-square btn-ghost" onClick={this.toggleAlarmsDrawer}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             className="inline-block w-6 h-6 stroke-current">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"/>
                        </svg>
                    </button>
                </div>

                {alarmsDrawer}
            </div>
        );
    }
}

Alarms.defaultProps = {
    actions: {},
    currentUser: {},
    requestNotifications: {},
};

Alarms.propTypes = {
    actions: PropTypes.object,
    currentUser: PropTypes.object,
    requestNotifications: PropTypes.object,
};

const mapStateToProps = state => ({
    currentUser: state.currentUser,
    requestNotifications: state.requestNotifications,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, notificationsActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Alarms);
