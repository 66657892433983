import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FileUpload from "../Common/FileUpload";
import { bindActionCreators } from 'redux';
import * as towerImageActions from '../../actions/mobileTowers/towerImageActions';
import { toast } from "react-hot-toast";

function SiteReportUpload({ actions, mobileTower, addTowerSiteReport }) {
    const onFileUploaded = (file) => {
        if (file) {
            actions.addTowerSiteReport(mobileTower.id, file.id);
            toast.success("File uploaded successfully");
        }
    };

    return (
        <div>
            <div
                className="cursor-pointer w-32 h-11 pt-3 bg-primary rounded-md text-sm font-bold text-white text-center uppercase"
            >
                <FileUpload
                    dropzoneClass={""}
                    fileId={0}
                    onFileUploaded={onFileUploaded}
                    promptText={'Upload'}
                    showPreview={false}
                    showRemove={false}
                    accept=".pdf/*"
                />
            </div>
        </div>
    )
}

SiteReportUpload.defaultProps = {
    actions: {},
};

SiteReportUpload.propTypes = {
    actions: PropTypes.object,
    addTowerChecklist: PropTypes.func.isRequired,
    mobileTower: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    uploadTowerSiteReport: state.uploadTowerSiteReport,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, towerImageActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteReportUpload);

