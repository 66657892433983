import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import classNames from 'classnames';
import UserDetails from './Header/UserDetails';
import SideNavMenu from './Header/SideNavMenu';
import ToggleFullScreen from './Header/ToggleFullScreen';
import Alarms from './Header/Alarms';
import {appFeatures, brandName} from '../../constants/appConfig';
import {suppressEvent} from "../../utils/general";
import SearchHeader from './Header/SearchHeader';
import CartButton from "./Header/CartButton";

function Header({fullScreenStatus, pageTitle}) {
    const [showSideNavMenu, setShowSideNavMenu] = useState(false);

    const toggleSideNav = (event) => {
        setShowSideNavMenu(!showSideNavMenu);
        // suppressEvent(event);
    };

    useEffect(() => {
        window.hideSideNav = function () {
            setShowSideNavMenu(false);
        };

        return () => {
            window.hideSideNav = null;
        };
    }, []);

    // if (showSideNavMenu) {
    //     pageTitle = brandName;
    // } else if (!pageTitle) {
    //     pageTitle = brandName;
    // }

    if (!pageTitle) {
        pageTitle = brandName;
    }

    return (
        <div className={classNames({
            'hidden': fullScreenStatus.enabled,
            'transition-all ease-in-out duration-200': true
        })}>
            {showSideNavMenu && (
                <SideNavMenu
                    toggleSideNav={toggleSideNav}
                />
            )}

            <div className="navbar shadow-lg bg-neutral text-neutral-content">
                <div className="container mx-auto">
                    <div className="flex-none">
                        <button className="btn btn-ghost -ml-4" onClick={toggleSideNav}>
                            {/*<img alt="Client Logo" src="/img/client-logo.png" className="w-12 block m-auto"/>*/}

                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 className="inline-block w-6 h-6 stroke-current">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M4 6h16M4 12h16M4 18h16"/>
                            </svg>
                        </button>
                    </div>

                    <div className="hidden lg:block">
                        <span className="text-2xl font-bold text-white">
                            {pageTitle}
                        </span>
                    </div>

                    <div className="grow"></div>

                    {appFeatures.search && (
                        <SearchHeader/>
                    )}

                    <div className="flex-none">
                        <UserDetails/>
                    </div>

                    {appFeatures.fullScreenButton && (
                        <div className="flex-none ml-3">
                            <ToggleFullScreen/>
                        </div>
                    )}

                    {appFeatures.notifications && (
                        <div className="flex-none ml-3">
                            <Alarms/>
                        </div>
                    )}

                    {appFeatures.shop && (
                        <div className="flex-none ml-3">
                            <CartButton />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

Header.defaultProps = {
    actions: {},
    fullScreenStatus: {},
    pageTitle: '',
};

Header.propTypes = {
    actions: PropTypes.object,
    fullScreenStatus: PropTypes.object,
    pageTitle: PropTypes.string,
};

const mapStateToProps = state => ({
    fullScreenStatus: state.fullScreenStatus,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
