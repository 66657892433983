import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {bindActionCreators} from "redux";
import * as notificationsActions from "../../../actions/notificationsActions";
import {connect} from "react-redux";
import {getFormattedDateStr} from "../../../utils/general";
import {
    getRequestNotificationIcon,
    getRequestNotificationTitle,
    getAlarmNotificationTitle,
    getAlarmNotificationIcon,
} from "../../../utils/notifications";
import AlarmAckModal from '../AlarmAckModal';

function AlarmsDrawer({toggleDrawer, requestNotifications, alarmNotifications}) {
    const [selectedAlarm, setSelectedAlarm] = useState(null);
    const [showAlarmAck, setShowAlarmAck]= useState(false);

    const toggleAcknowledge = () => {
        setShowAlarmAck(!showAlarmAck);
    };

    let alarmAckModal = null;

    if (showAlarmAck) {
        alarmAckModal = (
            <AlarmAckModal
                alarm={selectedAlarm}
                toggleModal={toggleAcknowledge}
            />
        );
    }

    // TODO Handle no notifications message

    let notificationItems = [];

    requestNotifications.ids.forEach((notificationId) => {
        const notification = requestNotifications.map[notificationId];

        const createdAt = getFormattedDateStr(notification.createdAt);
        const notificationTitle = getRequestNotificationTitle(notification);
        const notificationIcon = getRequestNotificationIcon(notification);

        notificationItems.push((
            <div className="w-full p-3 mb-1 bg-white rounded flex" key={notificationId}>
                {notificationIcon}

                <div className="pl-3 flex items-center justify-between flex-column w-full">
                    <div className="grow text-lg font-normal">
                        {notificationTitle}
                    </div>
                    <div className="flex-none text-end text-xs pt-1 text-gray-500">
                        {createdAt}
                    </div>
                </div>
            </div>
        ));
    });
    
    alarmNotifications.ids.forEach((alarmId) => {
        const alarm = alarmNotifications.map[alarmId];
        let alarmTime;

        if (alarm.alarmType === 'parameter') {
            if (alarm.currentStatus === 'out_of_limit') {
                alarmTime = getFormattedDateStr(alarm.outOfLimitTime);
            } else {
                alarmTime = getFormattedDateStr(alarm.backToLimitTime);
            }
        } else {
            alarmTime = getFormattedDateStr(alarm.createdAt);
        }

        const notificationTitle = getAlarmNotificationTitle(alarm);
        const notificationIcon = getAlarmNotificationIcon(alarm);
        const actionButtons=[];

        actionButtons.push((
            <button
                key={'btn-Acknowledge'}
                className="btn btn-primary btn-sm ml-4 mt-2 text-white"
                onClick={() => {
                    setSelectedAlarm(alarm);
                    setShowAlarmAck(true);
                }}
            >
              Acknowledge 
            </button>
        ));

        let colorClass = '';
        if (alarm.alarmType === 'parameter') {
            if (alarm.currentStatus === 'out_of_limit') {
                colorClass = 'text-red-500';
            } else {
                colorClass = 'text-green-500';
            }
        } else {
            if (alarm.alarmText.indexOf('restored') !== -1) {
                colorClass = 'text-green-500';
            } else {
                colorClass = 'text-orange-500';
            }
        }

        notificationItems.push((
            <div className={`p-3 mb-1 bg-white rounded ${colorClass}`} key={alarmId}>
                <div className="w-full flex">
                    <div className="pt-2">
                        {notificationIcon}
                    </div>

                    <div className="pl-3 flex items-center justify-between flex-column w-full">
                        <div className="grow text-lg font-normal pr-8">
                            {notificationTitle}
                        </div>
                        <div className="flex-none text-end text-xs pt-1 text-gray-500">
                            {alarmTime}
                            {actionButtons}
                        </div>
                    </div>
                </div>
            </div>
        ));
    });

    return (
        <div className="alarms-drawer transition-opacity ease-in-out">
            <div className="fixed w-screen h-screen top-0 left-0 bg-gray-800 opacity-50 z-30" onClick={toggleDrawer}/>

            <div className="absolute h-screen right-0 top-0 bg-white z-40 drop-shadow-lg w-9/12">
                <div className="navbar shadow-lg bg-neutral text-neutral-content pl-4">
                    <div className="flex-1">
                        <h2 className="text-2xl font-bold">Notifications</h2>
                    </div>

                    <div className="flex-wrap">
                        <button onClick={toggleDrawer} className="btn btn-ghost">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                        </button>
                    </div>
                </div>

                {/*<div>*/}
                {/*    <div className="tabs flex pt-3">*/}
                {/*        <a*/}
                {/*            className={classNames({*/}
                {/*                "tab tab-lifted tab-lg flex-wrap": true,*/}
                {/*                "tab-active": (activeTab === 'alarms')*/}
                {/*            })}*/}
                {/*            onClick={() => {*/}
                {/*                setActiveTab('alarms');*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            Alarms*/}
                {/*        </a>*/}
                {/*        <a*/}
                {/*            className={classNames({*/}
                {/*                "tab tab-lifted tab-lg flex-wrap": true,*/}
                {/*                "tab-active": (activeTab === 'alerts')*/}
                {/*            })}*/}
                {/*            onClick={() => {*/}
                {/*                setActiveTab('alerts');*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            Alerts*/}
                {/*        </a>*/}
                {/*        <a className="tab tab-lifted tab-lg flex-1 cursor-default">*/}
                {/*            &nbsp;*/}
                {/*        </a>*/}
                {/*    </div>*/}

                {/*    {tabContent}*/}
                {/*</div>*/}

                <div className="text-black notifications-container p-3 bg-gray-100">
                    {notificationItems}
                </div>
            </div>

            {alarmAckModal}
        </div>
    );
}

AlarmsDrawer.defaultProps = {
    currentUser: {},
    requestNotifications: {},
    alarmNotifications: {},
};

AlarmsDrawer.propTypes = {
    currentUser: PropTypes.object,
    requestNotifications: PropTypes.object,
    toggleDrawer: PropTypes.func.isRequired,
    alarmNotifications: PropTypes.object,
};

const mapStateToProps = state => ({
    currentUser: state.currentUser,
    requestNotifications: state.requestNotifications,
    alarmNotifications: state.alarmNotifications,
});

export default connect(mapStateToProps)(AlarmsDrawer);
