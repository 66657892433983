import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  DELETE_WORD_STARTING,
  DELETE_WORD_SUCCESS,
  DELETE_WORD_ERROR,
} from '../../constants/actionTypes';

export default function deleteWordReducer(state = initialState.deleteDictionaryWord, action) {
  switch (action.type) {
    case DELETE_WORD_STARTING: {
      const {wordId} = action;
      const newState = clone(state);

      if (!newState[wordId]) {
        newState[wordId] = clone(initialState.deleteDictionaryWord.wordId);
      }

      const deleteData = newState[wordId];
      deleteData.saving = true;
      deleteData.saved = false;
      deleteData.error = null;

      return newState;
    }

    case DELETE_WORD_SUCCESS: {
      const {wordId} = action;
      const newState = clone(state);

      if (!newState[wordId]) {
        newState[wordId] = clone(initialState.deleteDictionaryWord.wordId);
      }

      const deleteData = newState[wordId];
      deleteData.saving = false;
      deleteData.saved = true;
      deleteData.error = null;
      return newState;
    }

    case DELETE_WORD_ERROR: {
      const {wordId} = action;
      const newState = clone(state);

      if (!newState[wordId]) {
        newState[wordId] = clone(initialState.deleteDictionaryWord.wordId);
      }

      const deleteData = newState[wordId];
      deleteData.saving = false;
      deleteData.saved = false;
      deleteData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
