import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as dictionaryActions from "../../actions/dictionaryActions";
import {connect} from "react-redux";
import TextInput from "../Common/Utilities/Forms/TextInput";
import EntryTypeSelection from "../Common/Selection/Dictionary/EntryTypeSelection";
import LanguageSelection from "../Common/Selection/Dictionary/LanguageSelection";
import EmptyLoader from "../Common/EmptyLoader";
import EntryMeaningsEditor from "./EntryMeaningsEditor";
import {FaCheckCircle, FaPlusCircle, FaSave, FaSpinner, FaTimesCircle} from "react-icons/fa";
import {suppressEvent} from "../../utils/general";
import {toast} from "react-hot-toast";

function ManageEntry({actions, dictionaryEntries, activateDictionaryEntry, deleteDictionaryEntry, updateDictionaryEntry}) {
    const [name, setName] = useState('');
    const [entryType, setEntryType] = useState('');
    const [languageId, setLanguageId] = useState(0);
    const [remarks, setRemarks] = useState('');
    const [meanings, setMeanings] = useState([]);
    const [isSaving, setSaving] = useState(false);
    const [isDeleting, setDeleting] = useState(false);
    const [isActivating, setActivating] = useState(false);

    const entryId = dictionaryEntries.selectedEntryId;
    const entry = dictionaryEntries.map[entryId] ? dictionaryEntries.map[entryId] : null;
    const fullEntry = dictionaryEntries.fullMap[entryId] ? dictionaryEntries.fullMap[entryId] : null;

    useEffect(() => {
        if (fullEntry) {
            setName(fullEntry.name);
            setEntryType(fullEntry.entryType);
            setLanguageId(fullEntry.languageId);
            setRemarks(fullEntry.remarks);

            setMeanings(fullEntry.meanings.map((word) => word.word));
        } else if (entryId) {
            // get full entry
            actions.getEntryById(entryId);
        }
    }, [entry, fullEntry]);

    useEffect(() => {
        if (isSaving) {
            const updateData = updateDictionaryEntry[entryId];

            if (updateData && !updateData.saving && updateData.saved) {
                toast.dismiss();
                toast.success('Entry updated successfully.');
                setSaving(false);
                return;
            }

            if (updateData && !updateData.saving && !updateData.saved) {
                toast.dismiss();
                toast.error(updateData.error);
                setSaving(false);
            }
        }
    }, [updateDictionaryEntry]);

    useEffect(() => {
        if (isDeleting) {
            const deleteData = deleteDictionaryEntry[entryId];

            if (deleteData && !deleteData.saving && deleteData.saved) {
                toast.dismiss();
                toast.success('Entry deleted successfully.');
                setDeleting(false);
                return;
            }

            if (deleteData && !deleteData.saving && !deleteData.saved) {
                toast.dismiss();
                toast.error(deleteData.error);
                setDeleting(false);
            }
        }
    }, [deleteDictionaryEntry]);

    useEffect(() => {
        if (isActivating) {
            const activateData = activateDictionaryEntry[entryId];

            if (activateData && !activateData.saving && activateData.saved) {
                toast.dismiss();
                toast.success('Entry activated successfully.');
                setActivating(false);
                return;
            }

            if (activateData && !activateData.saving && !activateData.saved) {
                toast.dismiss();
                toast.error(activateData.error);
                setActivating(false);
            }
        }
    }, [activateDictionaryEntry]);
    
    if (!entryId) {
        return null;
    }

    if (!fullEntry) {
        return (
            <div className="pb-12">
                <EmptyLoader/>
            </div>
        );
    }

    const getEntryData = () => {
        return {
            entryType,
            name,
            languageId,
            remarks,
            meanings,
        };
    };

    let btnSave, btnDelete, btnActivate;

    if (entry.isActive === 1) {
        if (isSaving) {
            btnSave = (
                <button
                    type="button"
                    className="btn btn-sm btn-success text-white"
                    disabled={true}
                    onClick={() => {
                    }}
                >
                    <FaSpinner className="w-4 h-4 inline animate-spin"/>
                    Please wait
                </button>
            );
        } else {
            btnSave = (
                <button
                    type="button"
                    className="btn btn-sm btn-success text-white"
                    onClick={(event) => {
                        suppressEvent(event);
                        const postData = getEntryData();
                        actions.updateEntry(entryId, postData);
                        setSaving(true);
                    }}
                >
                    <FaSave className="w-4 h-4 inline"/>
                    Save Changes
                </button>
            );
        }
    }

    if (entry.isActive === 1) {
        if (isDeleting) {
            btnDelete = (
                <button
                    type="button"
                    className="btn btn-sm btn-error text-white"
                    disabled={true}
                    onClick={() => {
                    }}
                >
                    <FaSpinner className="w-4 h-4 inline animate-spin"/>
                    Please wait
                </button>
            );
        } else {
            btnDelete = (
                <button
                    type="button"
                    className="btn btn-sm btn-error text-white"
                    onClick={(event) => {
                        if (window.confirm('Are you sure you want to delete this entry?')) {
                            suppressEvent(event);
                            actions.deleteEntry(entryId);
                            setDeleting(true);
                        }
                    }}
                >
                    <FaTimesCircle className="w-4 h-4 inline"/>
                    Delete Entry
                </button>
            );
        }
    }

    if (entry.isActive === 0) {
        if (isActivating) {
            btnActivate = (
                <button
                    type="button"
                    className="btn btn-sm btn-primary text-white"
                    disabled={true}
                    onClick={() => {
                    }}
                >
                    <FaSpinner className="w-4 h-4 inline animate-spin"/>
                    Please wait
                </button>
            );
        } else {
            btnActivate = (
                <button
                    type="button"
                    className="btn btn-sm btn-primary text-white"
                    onClick={(event) => {
                        if (window.confirm('Are you sure you want to re-activate this entry?')) {
                            suppressEvent(event);
                            actions.activateEntry(entryId);
                            setActivating(true);
                        }
                    }}
                >
                    <FaCheckCircle className="w-4 h-4 inline"/>
                    Activate Entry
                </button>
            );
        }
    }

    return (
        <div className="p-4">
            <div className="grid grid-cols-2 gap-4">
                <EntryTypeSelection
                    value={entryType}
                    onChange={(e) => {
                        setEntryType(e.target.value);
                    }}
                />

                <LanguageSelection
                    label={"Language"}
                    value={languageId}
                    onChange={(e) => {
                        setLanguageId(e.target.value);
                    }}
                />

                <TextInput
                    value={name}
                    placeholder={""}
                    label={"Name"}
                    onChange={(event) => {
                        setName(event.target.value);
                    }}
                />

                <TextInput
                    type={"textarea"}
                    rows={4}
                    inputClassName="h-24"
                    value={remarks}
                    placeholder={""}
                    label={"Remarks"}
                    onChange={(event) => {
                        setRemarks(event.target.value);
                    }}
                />
            </div>

            <div className="mt-4">
                <EntryMeaningsEditor
                    meanings={meanings}
                    setMeanings={setMeanings}
                />
            </div>

            <hr className="my-4"/>

            <div className="mt-4 flex flex-row justify-between">
                {btnSave}
                {btnDelete}
                {btnActivate}
            </div>
        </div>
    );
}

ManageEntry.defaultProps = {
    actions: {},
    activateDictionaryEntry: {},
    deleteDictionaryEntry: {},
    dictionaryEntries: {},
    updateDictionaryEntry: {},
};

ManageEntry.propTypes = {
    actions: PropTypes.object,
    activateDictionaryEntry: PropTypes.object,
    deleteDictionaryEntry: PropTypes.object,
    dictionaryEntries: PropTypes.object,
    updateDictionaryEntry: PropTypes.object,
};

const mapStateToProps = state => ({
    activateDictionaryEntry: state.activateDictionaryEntry,
    deleteDictionaryEntry: state.deleteDictionaryEntry,
    dictionaryEntries: state.dictionaryEntries,
    updateDictionaryEntry: state.updateDictionaryEntry,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, dictionaryActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageEntry);
