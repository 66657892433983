import React from 'react';
import ProtectedRoute from '../../components/Common/ProtectedRoute';
import AppLayout from '../../components/Common/AppLayout';
import LettersList from "../../components/Dictionary/LettersList";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as dictionaryActions from "../../actions/dictionaryActions";
import {connect} from "react-redux";
import {acharyaCred} from "../../constants/appConfig";
import ManageEntry from "../../components/Dictionary/ManageEntry";

function PrefixesList({  }) {
    return (
        <ProtectedRoute>
            <AppLayout isLongPage={true} pageTitle={`Encyclopaedia`} footerRightText={acharyaCred}>
                <div className="dictionary-layout container mx-auto">
                    <div className="flex flex-row mt-4 gap-4">
                        <div className="basis-1/5 dictionary-words-sidenav">
                            <div className="bg-white p-4 rounded-md">
                                <LettersList />
                            </div>
                        </div>

                        <div className="basis-4/5">
                            <div className="bg-white rounded-md">
                                <ManageEntry />
                            </div>
                        </div>
                    </div>

                </div>
            </AppLayout>
        </ProtectedRoute>
    );
}

PrefixesList.defaultProps = {
    actions: {},
};

PrefixesList.propTypes = {
    actions: PropTypes.object,
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, dictionaryActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrefixesList);
