import {
    ADD_CLIENT_LOGO_STARTING,
    ADD_CLIENT_LOGO_SUCCESS,
    ADD_CLIENT_LOGO_ERROR,

} from '../../constants/actionTypes';
import axios from '../../axios';
import {
    ADD_CLIENT_LOGO,

} from '../../constants/apiUrls';


export function addClientLogoStarting(mobileTowerId) {
    return { type: ADD_CLIENT_LOGO_STARTING, mobileTowerId };
}

export function addClientLogoSuccess(mobileTowerId, data) {
    return { type: ADD_CLIENT_LOGO_SUCCESS, mobileTowerId, data };
}

export function addClientLogoError(mobileTowerId, err) {
    return { type: ADD_CLIENT_LOGO_ERROR, mobileTowerId, err };
}

export function addClientLogo(mobileTowerId, fileId) {
    return (dispatch) => {
        dispatch(addClientLogoStarting(mobileTowerId));

        axios.post(ADD_CLIENT_LOGO, {
            mobileTowerId,
            fileId,
        }).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(addClientLogoSuccess(mobileTowerId, response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to upload Tower Image';
            dispatch(addClientLogoError(mobileTowerId, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(addClientLogoError(mobileTowerId, error));
        });
    };
}

