import {roundNumber} from './math';
import { round } from 'lodash';

export function getCurrencyFormattedNumber(value) {
    if (value === null) {
        return '';
    }

    return '$' + getFormattedNumber(value); // eslint-disable-line prefer-template
}

export function getFormattedNumber(value) {
    if (value === 0) {
        return 0;
    }

    if (!value) {
        return '';
    }

    if (!isInt(scrubFormatting(value))) {
        return ''; // if it's not a number after scrubbing formatting, just return empty.
    }

    let roundedValue = roundNumber(value, 2); // round if more than 2 decimal points
    roundedValue = roundedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); // add commas for 1,000's. RegEx from http://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
    const roundedValueContainsDecimalPlace = (roundedValue.indexOf('.') !== -1);

    if (roundedValueContainsDecimalPlace) {
        const numbersToTheRightOfDecimal = roundedValue.split('.')[1];

        switch (numbersToTheRightOfDecimal.length) {
            case 0:
                return roundedValue.replace('.', ''); // no decimal necessary since no numbers after decimal
            case 1:
                return `${roundedValue}0`;
            default:
                return roundedValue;
        }
    }
    return roundedValue;
}

export function isInt(n) {
    if (n === '' || n === null) {
        return false;
    }

    return n % 1 === 0;
}

export function scrubFormatting(value) {
    return value.toString().replace('$', '').replace(',', '').replace('.', '');
}

export function humanFileSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    const r = 10**dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}

export function toHumanFormat(num) {
    let txt = '';

    if (num > 1e9) {
        txt = `${round(num / 1e9, 2)}B`;
    } else if (num > 1e6) {
        txt = `${round(num / 1e6, 2)}M`;
    } else if (num > 1e3) {
        txt = `${round(num / 1e3, 2)}k`;
    } else {
        txt = num.toString();
    }

    return txt;
}
