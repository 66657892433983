import { useEffect, useRef } from 'react';
import WebViewer from '@pdftron/pdfjs-express-viewer';
import { getFileUri } from '../../utils/navigation';
import TowerSiteReportEditUpload from './TowerSIteReportEditUpload';

const TowerSiteReport = ({ editMode, mobileTower }) => {
  const viewer = useRef(null);

  let siteReport = null;

  if (mobileTower.siteReport) {
    siteReport = mobileTower.siteReport[0];
  }

  useEffect(() => {
    if (siteReport && viewer) {
      const filePath = getFileUri(siteReport.id);
      WebViewer(
        {
          path: '/pdfjs-express',
          licenseKey: 'Y4lLh43AZEee3d5g1EfQ',
          initialDoc: filePath,

          // initialDoc: '/files/report1.pdf',
        },
        viewer.current,
      ).then((instance) => {
        // now you can access APIs through the WebViewer instance
        const { Core } = instance;

        // adding an event listener for when a document is loaded
        Core.documentViewer.addEventListener('documentLoaded', () => {
          console.log('document loaded');
        });

        // adding an event listener for when the page number has changed
        Core.documentViewer.addEventListener('pageNumberUpdated', (pageNumber) => {
          console.log(`Page number is: ${pageNumber}`);
        });
      });
    }
  }, [siteReport]);

  if (editMode) {
    return (
      <TowerSiteReportEditUpload mobileTower={mobileTower} />
    );
  }
  let content;
  if (!siteReport) {
    content = (
      <div style={{ height: '100px' }} className="flex flex-col justify-center">
        <div className="text-center text-sm text-black font-bold">
          No Site Report available.
        </div>
      </div>
    );
  } else {
    content = (
      <div className="webviewer" ref={viewer} style={{ height: "100vh" }}></div>
    );
  }

  return (
    <div className="pdf-file-container">
      {content}
    </div>
  );
};

export default TowerSiteReport;