import {
    GET_WORDS_STARTING,
    GET_WORDS_SUCCESS,
    GET_WORDS_ERROR,
    SET_DICTIONARY_LETTERS,
    SET_DICTIONARY_LETTER_OPEN,
    GET_ENTRIES_STARTING,
    GET_ENTRIES_SUCCESS,
    GET_ENTRIES_ERROR,
    GET_LANGUAGES_STARTING,
    GET_LANGUAGES_SUCCESS,
    GET_LANGUAGES_ERROR,
    GET_ENTRY_STARTING,
    GET_ENTRY_SUCCESS,
    GET_ENTRY_ERROR,
    SET_SELECTED_ENTRY,
    SET_SELECTED_WORD,
    UPDATE_WORD_STARTING,
    UPDATE_WORD_SUCCESS,
    UPDATE_WORD_ERROR,
    ADD_WORD_STARTING,
    ADD_WORD_SUCCESS,
    ADD_WORD_ERROR,
    DELETE_WORD_STARTING,
    DELETE_WORD_SUCCESS,
    DELETE_WORD_ERROR,
    SET_ENTRY_SEARCH_BY,
    SET_ENTRY_SEARCH_QUERY,
    UPDATE_ENTRY_STARTING,
    UPDATE_ENTRY_SUCCESS,
    UPDATE_ENTRY_ERROR,
    ADD_ENTRY_STARTING,
    ADD_ENTRY_SUCCESS,
    ADD_ENTRY_ERROR,
    DELETE_ENTRY_STARTING,
    DELETE_ENTRY_SUCCESS,
    DELETE_ENTRY_ERROR,
    ACTIVATE_ENTRY_STARTING,
    ACTIVATE_ENTRY_SUCCESS,
    ACTIVATE_ENTRY_ERROR,
    ACTIVATE_WORD_STARTING,
    ACTIVATE_WORD_ERROR,
    ACTIVATE_WORD_SUCCESS,
} from '../constants/actionTypes';
import axios from "../axios";
import {
    GET_WORDS, GET_ENTRIES, GET_LANGUAGES,
    GET_ENTRY, UPDATE_DICTIONARY_WORD,
    ADD_DICTIONARY_WORD, DELETE_DICTIONARY_WORD,
    UPDATE_DICTIONARY_ENTRY,
    ADD_DICTIONARY_ENTRY, DELETE_DICTIONARY_ENTRY,
    ACTIVATE_DICTIONARY_ENTRY,
    ACTIVATE_DICTIONARY_WORD,
} from '../constants/apiUrls';

export function getLanguagesStarting() {
    return { type: GET_LANGUAGES_STARTING };
}

export function getLanguagesSuccess(data) {
    return { type: GET_LANGUAGES_SUCCESS, data }
}

export function getLanguagesFailed(err) {
    return { type: GET_LANGUAGES_ERROR, err }
}

export function getLanguages() {
    return dispatch => (
        axios.get(GET_LANGUAGES).then((response) => {
            if (response && response.status === 200 && response.data.success) {
                dispatch(getLanguagesSuccess(response.data));
                return;
            }
            const err = (response && response.data && response.data.error) ?
                new Error(response.data.error) : new Error('Failed to get languages');
            dispatch(getLanguagesFailed(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            dispatch(getLanguagesFailed(error));
        }));
}

export function setDictionaryLetters() {
  return { type: SET_DICTIONARY_LETTERS };
}

export function setLetterOpen(letter, isOpen) {
  return { type: SET_DICTIONARY_LETTER_OPEN, letter, isOpen };
}

export function setSelectedEntry(entry) {
    return { type: SET_SELECTED_ENTRY, entry };
}

export function setSelectedWord(word) {
    return { type: SET_SELECTED_WORD, word };
}

export function getWordsStarting(criteria) {
  return { type: GET_WORDS_STARTING, criteria };
}

export function getWordsSuccess(criteria, data) {
  return { type: GET_WORDS_SUCCESS, criteria, data }
}

export function getWordsFailed(criteria, err) {
  return { type: GET_WORDS_ERROR, criteria, err }
}

export function getWords(criteria) {
  return dispatch => (
      axios.get(GET_WORDS, {
        params: criteria,
      }).then((response) => {
        if (response && response.status === 200 && response.data.success) {
          dispatch(getWordsSuccess(criteria, response.data));
          return;
        }
        const err = (response && response.data && response.data.error) ?
            new Error(response.data.error) : new Error('Failed to get words list');
        dispatch(getWordsFailed(criteria, err));
      }).catch((err) => {
        const error = (err.response && err.response.data && err.response.data.error) ?
            new Error(err.response.data.error) : err;
        dispatch(getWordsFailed(criteria, error));
      }));
}

export function getEntriesStarting(criteria) {
  return { type: GET_ENTRIES_STARTING, criteria };
}

export function getEntriesSuccess(criteria, data) {
  return { type: GET_ENTRIES_SUCCESS, criteria, data }
}

export function getEntriesFailed(criteria, err) {
  return { type: GET_ENTRIES_ERROR, criteria, err }
}

export function getEntries(criteria) {
  return dispatch => (
      axios.get(GET_ENTRIES, {
        params: criteria,
      }).then((response) => {
        if (response && response.status === 200 && response.data.success) {
          dispatch(getEntriesSuccess(criteria, response.data));
          return;
        }
        const err = (response && response.data && response.data.error) ?
            new Error(response.data.error) : new Error('Failed to get entries list');
        dispatch(getEntriesFailed(criteria, err));
      }).catch((err) => {
        const error = (err.response && err.response.data && err.response.data.error) ?
            new Error(err.response.data.error) : err;
        dispatch(getEntriesFailed(criteria, error));
      }));
}

export function getEntryByIdStarting(entryId) {
    return { type: GET_ENTRY_STARTING, entryId };
}

export function getEntryByIdSuccess(entryId, data) {
    return { type: GET_ENTRY_SUCCESS, entryId, data }
}

export function getEntryByIdFailed(entryId, err) {
    return { type: GET_ENTRY_ERROR, entryId, err }
}

export function getEntryById(entryId) {
    const url = GET_ENTRY.replace('{entryId}', entryId);
    return dispatch => (
        axios.get(url).then((response) => {
            if (response && response.status === 200 && response.data.success) {
                dispatch(getEntryByIdSuccess(entryId, response.data));
                return;
            }
            const err = (response && response.data && response.data.error) ?
                new Error(response.data.error) : new Error('Failed to get entries list');
            dispatch(getEntryByIdFailed(entryId, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            dispatch(getEntryByIdFailed(entryId, error));
        }));
}

export function addWordStarting() {
    return {type: ADD_WORD_STARTING};
}

export function addWordSuccess(data) {
    return {type: ADD_WORD_SUCCESS, data};
}

export function addWordError(err) {
    return {type: ADD_WORD_ERROR, err};
}

export function addWord(wordData) {
    return (dispatch) => {
        dispatch(addWordStarting());

        axios.post(ADD_DICTIONARY_WORD, wordData).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(addWordSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to add word';
            dispatch(addWordError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(addWordError(error));
        });
    };
}

export function updateWordStarting(wordId) {
    return {type: UPDATE_WORD_STARTING, wordId};
}

export function updateWordSuccess(wordId, data) {
    return {type: UPDATE_WORD_SUCCESS, wordId, data};
}

export function updateWordError(wordId, err) {
    return {type: UPDATE_WORD_ERROR, wordId, err};
}

export function updateWord(wordId, wordData) {
    return (dispatch) => {
        dispatch(updateWordStarting(wordId));

        const url = UPDATE_DICTIONARY_WORD.replace('{wordId}', wordId);
        axios.patch(url, wordData).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(updateWordSuccess(wordId, response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to update word';
            dispatch(updateWordError(wordId, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(updateWordError(wordId, error));
        });
    };
}

export function deleteWordStarting(wordId) {
    return {type: DELETE_WORD_STARTING, wordId};
}

export function deleteWordSuccess(wordId, data) {
    return {type: DELETE_WORD_SUCCESS, wordId, data};
}

export function deleteWordError(wordId, err) {
    return {type: DELETE_WORD_ERROR, wordId, err};
}

export function deleteWord(wordId) {
    return (dispatch) => {
        dispatch(deleteWordStarting(wordId));

        const url = DELETE_DICTIONARY_WORD.replace('{wordId}', wordId);
        axios.delete(url).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(deleteWordSuccess(wordId, response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to delete word';
            dispatch(deleteWordError(wordId, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(deleteWordError(wordId, error));
        });
    };
}

export function activateWordStarting(wordId) {
    return {type: ACTIVATE_WORD_STARTING, wordId};
}

export function activateWordSuccess(wordId, data) {
    return {type: ACTIVATE_WORD_SUCCESS, wordId, data};
}

export function activateWordError(wordId, err) {
    return {type: ACTIVATE_WORD_ERROR, wordId, err};
}

export function activateWord(wordId) {
    return (dispatch) => {
        dispatch(activateWordStarting(wordId));

        const url = ACTIVATE_DICTIONARY_WORD.replace('{wordId}', wordId);
        axios.patch(url, {
            isActive: 1,
        }).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(activateWordSuccess(wordId, response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to activate word';
            dispatch(activateWordError(wordId, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(activateWordError(wordId, error));
        });
    };
}

export function setEntrySearchBy(searchBy) {
    return {type: SET_ENTRY_SEARCH_BY, searchBy};
}

export function setEntrySearchQuery(searchQuery) {
    return { type: SET_ENTRY_SEARCH_QUERY, searchQuery};
}

export function addEntryStarting() {
    return {type: ADD_ENTRY_STARTING};
}

export function addEntrySuccess(data) {
    return {type: ADD_ENTRY_SUCCESS, data};
}

export function addEntryError(err) {
    return {type: ADD_ENTRY_ERROR, err};
}

export function addEntry(entryData) {
    return (dispatch) => {
        dispatch(addEntryStarting());

        axios.post(ADD_DICTIONARY_ENTRY, entryData).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(addEntrySuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to add entry';
            dispatch(addEntryError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(addEntryError(error));
        });
    };
}

export function updateEntryStarting(entryId) {
    return {type: UPDATE_ENTRY_STARTING, entryId};
}

export function updateEntrySuccess(entryId, data) {
    return {type: UPDATE_ENTRY_SUCCESS, entryId, data};
}

export function updateEntryError(entryId, err) {
    return {type: UPDATE_ENTRY_ERROR, entryId, err};
}

export function updateEntry(entryId, entryData) {
    return (dispatch) => {
        dispatch(updateEntryStarting(entryId));

        const url = UPDATE_DICTIONARY_ENTRY.replace('{entryId}', entryId);
        axios.put(url, entryData).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(updateEntrySuccess(entryId, response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to update entry';
            dispatch(updateEntryError(entryId, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(updateEntryError(entryId, error));
        });
    };
}

export function deleteEntryStarting(entryId) {
    return {type: DELETE_ENTRY_STARTING, entryId};
}

export function deleteEntrySuccess(entryId, data) {
    return {type: DELETE_ENTRY_SUCCESS, entryId, data};
}

export function deleteEntryError(entryId, err) {
    return {type: DELETE_ENTRY_ERROR, entryId, err};
}

export function deleteEntry(entryId) {
    return (dispatch) => {
        dispatch(deleteEntryStarting(entryId));

        const url = DELETE_DICTIONARY_ENTRY.replace('{entryId}', entryId);
        axios.delete(url).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(deleteEntrySuccess(entryId, response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to delete entry';
            dispatch(deleteEntryError(entryId, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(deleteEntryError(entryId, error));
        });
    };
}

export function activateEntryStarting(entryId) {
    return {type: ACTIVATE_ENTRY_STARTING, entryId};
}

export function activateEntrySuccess(entryId, data) {
    return {type: ACTIVATE_ENTRY_SUCCESS, entryId, data};
}

export function activateEntryError(entryId, err) {
    return {type: ACTIVATE_ENTRY_ERROR, entryId, err};
}

export function activateEntry(entryId) {
    return (dispatch) => {
        dispatch(activateEntryStarting(entryId));

        const url = ACTIVATE_DICTIONARY_ENTRY.replace('{entryId}', entryId);
        axios.patch(url, {
            isActive: 1,
        }).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(activateEntrySuccess(entryId, response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to activate entry';
            dispatch(activateEntryError(entryId, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(activateEntryError(entryId, error));
        });
    };
}
