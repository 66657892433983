import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  UPDATE_ENTRY_STARTING,
  UPDATE_ENTRY_SUCCESS,
  UPDATE_ENTRY_ERROR,
} from '../../constants/actionTypes';

export default function updateEntryReducer(state = initialState.updateDictionaryEntry, action) {
  switch (action.type) {
    case UPDATE_ENTRY_STARTING: {
      const {entryId} = action;
      const newState = clone(state);

      if (!newState[entryId]) {
        newState[entryId] = clone(initialState.updateDictionaryEntry.entryId);
      }

      const updateData = newState[entryId];
      updateData.saving = true;
      updateData.saved = false;
      updateData.error = null;

      return newState;
    }

    case UPDATE_ENTRY_SUCCESS: {
      const {entryId} = action;
      const newState = clone(state);

      if (!newState[entryId]) {
        newState[entryId] = clone(initialState.updateDictionaryEntry.entryId);
      }

      const updateData = newState[entryId];
      updateData.saving = false;
      updateData.saved = true;
      updateData.error = null;
      return newState;
    }

    case UPDATE_ENTRY_ERROR: {
      const {entryId} = action;
      const newState = clone(state);

      if (!newState[entryId]) {
        newState[entryId] = clone(initialState.updateDictionaryEntry.entryId);
      }

      const updateData = newState[entryId];
      updateData.saving = false;
      updateData.saved = false;
      updateData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
